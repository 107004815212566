import { Card } from 'reactstrap'
import './item.scss'
const Item = ({ element, index, showStatus = false, showTwp = false }) => {
  return (
    <Card className="item-request-item">
      <div className="item-request-item__info">
        <span className="item-request-item__info__no">{index + 1}.</span>
        <span className="item-request-item__info__title">
          {element.date}

          {showTwp && <span> ({element?.township?.name})</span>}
          <span style={{ marginLeft: '10px' }}>({element?.status})</span>
        </span>
      </div>
      <div className="item-request-item__actions">
        <span className="bg-success">
          {element.warehouse_issued_items.length}
        </span>
        Items
      </div>
    </Card>
  )
}

export default Item
