import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getItemDispensings,
  getItemDispensing,
  addItemDispensing,
  editItemDispensing,
  deleteItemDispensing,
} from 'api/itemDispensing'
const ItemDispensingContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})
export function ItemDispensingContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      let response = await getItemDispensings(params)
      setItems(response)
      return response
    },
    getEachData: async (id) => {
      let response = await getItemDispensing(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      if (await addItemDispensing(values)) {
        NotificationManager.success('Success')
      }
    },
    editData: async (values, id) => {
      if (await editItemDispensing(values, id)) {
        NotificationManager.success('Success')
      }
    },
    deleteData: async (id, visit_id) => {
      if (await deleteItemDispensing(id)) {
        NotificationManager.success('Success')
      }
    },
  }
  return (
    <ItemDispensingContext.Provider value={context}>
      {props.children}
    </ItemDispensingContext.Provider>
  )
}
export default ItemDispensingContext
