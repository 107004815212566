import { Row, Col, Button, Input, Label } from 'reactstrap'
import { useContext } from 'react'
import { Trash } from 'react-bootstrap-icons'
import FormGroup from '../layouts/formGroup'
import AddForm from './AddForm'
import CustomSelect from './../elements/customSelect'
import CustomDatePicker from 'components/elements/customDatePicker'
import ItemContext from 'store/itemContext'
import PatientContext from 'store/patientContext'

const RequestForm = ({
  selectItems,
  changeQty,
  choose,
  removeItem,
  nextTo,
  items,
  date,
  setDate,
}) => {
  return (
    <>
      <AddForm
        onChoose={(selectedItem) => choose(selectedItem)}
        items={items}
      />
      <FormGroup title="Selected Items">
        {selectItems.map((item, index) => {
          return (
            <Row className="mb-2">
              <Col md={6} xs={7}>
                <Input value={item.id.label} disabled />
              </Col>
              <Col md={2} xs={2}>
                <Input
                  min="1"
                  type="number"
                  value={item.qty}
                  onChange={(e) => changeQty(index, e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value[0] === '0') changeQty(index, 1)
                  }}
                />
              </Col>
              <Col md={2} xs={2}>
                <Input
                  placeholder="Issue Out (Damage)"
                  min="1"
                  type="number"
                  onChange={(e) => changeQty(index, e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value[0] === '0') changeQty(index, 1)
                  }}
                />
              </Col>
              <Col xs={2}>
                <Button color="danger" onClick={() => removeItem(item.id)}>
                  <Trash />
                </Button>
              </Col>
            </Row>
          )
        })}
      </FormGroup>
      <FormGroup title="Info">
        <Row>
          <Col md="3">
            <Label>Date</Label>
            <CustomDatePicker value={date} onChange={(day) => setDate(day)} />
          </Col>
        </Row>
      </FormGroup>

      <Row className="mt-3">
        <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            onClick={nextTo}
            disabled={selectItems.length === 0}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  )
}
export default RequestForm
