import { useState, useContext, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Button } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import AdminLayout from 'layouts/admin'
import InfoForm from 'components/patient/infoForm'
import PatientContext from 'store/patientContext'
import LoadingContext from 'store/loadingContext'
import { getTodayDate } from 'lib/dateFormat'
import './patient.scss'

const NewPatient = () => {
  let initalValues = {
    register_date: getTodayDate(),
    name: '',
    age: '',
    sex: '',
    education: '',
    address: '',
    phone_number: '',
    marital_status: '',
    occupation: '',
    risk_factors: [],
    risk_factor_other: '',
    vaccinated: '',
    vaccine_name: '',
    vaccine_doses: '',
    last_vaccine_date: '',
    contact_with_19: '',
    contact_with_19_place: '',
    local_travel: '',
    international_travel: '',
  }
  const [household, setHouseholds] = useState([])
  const { addData } = useContext(PatientContext)
  const { addActionLoading, actionLoading } = useContext(LoadingContext)

  const validate = (values) => {
    let validate = true
    if (!values.type) validate = false
    if (!values.code) validate = false
    if (!values.register_date) validate = false
    if (!values.name) validate = false
    if (!values.age) validate = false
    if (!values.sex) validate = false
    if (!values.education) validate = false
    if (!values.address) validate = false
    if (!values.phone_number) validate = false
    if (!values.marital_status) validate = false
    if (!values.occupation) validate = false
    if (!values.risk_factors.length === 0) validate = false

    return validate
  }

  const submitData = async (values) => {
    values.name_ofhousehold = JSON.stringify(household)
    values.risk_factors = JSON.stringify(values.risk_factors)
    if (validate(values)) {
      addActionLoading(true)
      await addData({ patient: values })
      addActionLoading(false)
    } else {
      NotificationManager.error('Some fields are required')
    }
  }

  return (
    <AdminLayout>
      <h5>New Patient</h5>
      <Formik
        initialValues={initalValues}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={(values) => submitData(values)}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <InfoForm
              formik={formik}
              household={household}
              setHouseholds={setHouseholds}
            />
            <Row className="mt-3">
              <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
                <Button
                  className="submit-btn mb-3"
                  color="primary"
                  disabled={actionLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </AdminLayout>
  )
}
export default NewPatient
