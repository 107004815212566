import { Card } from 'reactstrap'
import './item.scss'
const Item = ({ element, index }) => {
  return (
    <Card className="item-list-item">
      <div className="item-list-item__info">
        <span className="item-list-item__info__no">{index + 1}.</span>
        <span className="item-list-item__info__title">
          {element.dispence_date}
        </span>
      </div>
      <div className="item-list-item__actions">
        <span className="bg-success">
          {element?.township_store_dispenced_items.length}
        </span>
        Items
      </div>
    </Card>
  )
}

export default Item
