import { useContext } from 'react'
import { Table, Button } from 'reactstrap'
import PatientContext from 'store/patientContext'
const ReviewPage = ({
  selectItems,
  backTo,
  showActions = true,
  onSave,
  date,
}) => {
  return (
    <>
      {/* <h6>Items for {patients.find((val) => val.id === patient)?.name}</h6> */}
      {date && <h6>Created at {date}</h6>}
      <Table bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectItems?.map((element, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{element.id.label}</td>
                <td>{element.qty}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div className="text-center pt-3">
        {showActions && (
          <>
            <Button onClick={backTo}>Back</Button>
            <Button
              onClick={onSave}
              style={{ marginLeft: '20px' }}
              color="primary"
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </>
  )
}
export default ReviewPage
