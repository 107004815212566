import { Row, Col, Card } from 'reactstrap'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from 'store/authContext'
import './link-buttons.scss'
const LinkButtons = ({ visitID }) => {
  let prefix = 'user'
  const { data } = useContext(AuthContext)

  if (data.role === 'admin') {
    prefix = 'admin'
  }

  return (
    <Row className="link-buttons">
      <Col md={4} sm={6} xs={12}>
        <Link to={`/${prefix}/daily-monitoring/${visitID}`}>
          <Card className="link-buttons__card">
            <h5>Daily Monitoring</h5>
          </Card>
        </Link>
      </Col>
      <Col md={4} sm={6} xs={12}>
        <Link to={`/${prefix}/lab/${visitID}`}>
          <Card className="link-buttons__card">
            <h5>Lab Investigation</h5>
          </Card>
        </Link>
      </Col>
      <Col md={4} sm={6} xs={12}>
        <Link to={`/${prefix}/dispense/${visitID}`}>
          <Card className="link-buttons__card">
            <h5>Medical History</h5>
          </Card>
        </Link>
      </Col>
    </Row>
  )
}

export default LinkButtons
