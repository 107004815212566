import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Table, Button } from 'reactstrap'
import Info from './info'
import ItemContext from 'store/itemContext'
import AuthContext from 'store/authContext'

const ReviewPage = ({
  selectItems,
  approvedItems,
  backTo,
  showActions = true,
  info,
  onSave,
  deleteRequest,
}) => {
  const { data } = useContext(ItemContext)
  const authContext = useContext(AuthContext)
  const { id } = useParams()

  return (
    <>
      <Info info={info} />
      <h6>Requested Items</h6>
      <Table bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectItems?.map((element, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{`${data.find((ele) => ele.id === element.id)?.item} ${
                  data.find((ele) => ele.id === element.id)?.au
                }`}</td>
                <td>{element.qty}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {approvedItems?.length > 0 && (
        <>
          <h6>Approved Items</h6>
          <Table bordered>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {approvedItems?.map((element, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.find((ele) => ele.id === element.id)?.items}</td>
                    <td>{element.qty}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </>
      )}
      <h6>Remark</h6>
      <p>{info?.remark}</p>
      <div className="text-center pt-3">
        {showActions && (
          <>
            -<Button onClick={backTo}>Back</Button>
            <Button
              onClick={onSave}
              style={{ marginLeft: '20px' }}
              color="primary"
            >
              Submit
            </Button>
          </>
        )}
        {authContext.role != 'warehouse' &&
          info.status == 'pending' &&
          !showActions && (
            <>
              <Link to={`/user/edit-item-request/${id}`}>
                <Button color="primary">Edit</Button>
              </Link>
              <Button
                style={{ marginLeft: '20px' }}
                onClick={deleteRequest}
                color="danger"
              >
                Delete
              </Button>
            </>
          )}
      </div>
    </>
  )
}
export default ReviewPage
