import { NotificationManager } from "react-notifications";
import {  sendData } from "./apiService.js";
import { storeCache, removeCache } from "./../lib/cache.js";
export async function loginReq(values) {
  let response = await sendData("login", values);
  if (response) {
    storeCache("token", response.token);
    NotificationManager.success("Success");
    storeCache("userData", JSON.stringify(response.user));
    return response.user;
  }
}

export function logout() {
  removeCache("token");
  removeCache("userData");
  NotificationManager.success("Logout");
  return true;
}
