import { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, Button } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import UserLayout from 'layouts/user'
import FormGroup from 'components/layouts/formGroup'
import ReviewPage from 'components/item-request/reivewPage'
import CustomDatePicker from 'components/elements/customDatePicker'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import LoadingContext from 'store/loadingContext'
import ItemContext from 'store/itemContext'
import { getTodayDate } from 'lib/dateFormat'
const ItemRequestReview = () => {
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)
  const {
    getEachData: getIssue,
    eachData: issue,
    deleteData: deleteIssue,
    editData: updateIssue,
  } = useContext(WarehouseIssueContext)
  const [items, setItem] = useState([])
  const [date, setDate] = useState(getTodayDate())
  const itemCtx = useContext(ItemContext)
  const { id } = useParams()
  let history = useHistory()
  const deleteRequest = async () => {
    addActionLoading(true)
    await deleteIssue(id)
    addActionLoading(false)
    history.push('/user/item-request')
  }
  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await itemCtx.getData()
      let reponse = await getIssue(id)
      let items = reponse.warehouse_issued_items.map((element) => {
        return {
          id: element.item_id,
          qty: element.quantity,
        }
      })
      setItem(items)
      addLoading(false)
    }
    fetchApi()
  }, [])
  const onReceive = async () => {
    let values = {
      status: 'receive',
      receive_date: date,
    }
    await updateIssue({ issue: values }, id)
    window.location.reload()
  }
  return (
    <UserLayout>
      <Card className="mb-5">
        <CardBody>
          <ReviewPage
            showActions={false}
            selectItems={items}
            approvedItems={issue?.approved_items}
            deleteRequest={deleteRequest}
            info={{
              for: issue?.township?.name,
              status: issue?.status,
              date: issue?.date,
              accept_date: issue?.accept_date,
              delivery_date: issue?.deliever_date,
              receive_date: issue?.receive_date,
            }}
          />
        </CardBody>
      </Card>
      {issue?.status === 'deliver' && (
        <FormGroup title="Receive Information">
          <Row>
            <Col>
              <CustomDatePicker onChange={(day) => setDate(day)} />
            </Col>
            <Col>
              <Button color="primary" onClick={onReceive}>
                Receive
              </Button>
            </Col>
          </Row>
        </FormGroup>
      )}
    </UserLayout>
  )
}
export default ItemRequestReview
