import { getData, sendData } from './apiService.js'
export async function getWarehouseReceives(params = {}) {
  let response = await getData(`warehousereceives`, params)
  return response
}
export async function getWarehouseReceive(id) {
  let response = await getData(`warehousereceives/${id}`)
  return response
}
export async function addWarehouseReceive(values) {
  return await sendData('warehousereceives', values)
}
export async function editWarehouseReceive(values, id) {
  return await sendData(`warehousereceives/${id}`, values, 'PUT')
}

export async function deleteWarehouseReceive(id) {
  return await sendData(`warehousereceives/${id}`, {}, 'DELETE')
}
