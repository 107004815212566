import { useContext } from 'react'
import { Table } from 'reactstrap'
import TownshipContext from 'store/townshipContext'
import AuthContext from 'store/authContext'
const Info = ({ info }) => {
  const { data: townships } = useContext(TownshipContext)
  const { data: user } = useContext(AuthContext)
  return (
    <Table>
      {user.role === 'warehouse' && (
        <tr>
          <td>Township</td>
          <td>{info?.for}</td>
        </tr>
      )}
      <tr>
        <td>Status</td>
        <td>{info?.status}</td>
      </tr>
      <tr>
        <td>Request Date</td>
        <td>{info?.date}</td>
      </tr>
      <tr>
        <td>Confirm Date</td>
        <td>{info?.accept_date}</td>
      </tr>
      <tr>
        <td>Delivery Date</td>
        <td>{info?.delivery_date}</td>
      </tr>
      <tr>
        <td>Receive Date</td>
        <td>{info?.receive_date}</td>
      </tr>
    </Table>
  )
}
export default Info
