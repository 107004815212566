import { useState } from 'react'
import { Row, Col, Label, Button } from 'reactstrap'
import FormGroup from './../layouts/formGroup'
import CustomSelect from './../elements/customSelect'
const AddForm = ({ onChoose, items }) => {
  const [selectedItem, setSelectItem] = useState(null)
  return (
    <FormGroup title="Choose Item">
      <Row>
        <Col md={4} sm={6} xs={10}>
          <Label>Items</Label>
          <CustomSelect
            options={items.map((element) => {
              return {
                value: element.id,
                label: `${element.item.item} ${element.item.au}`,
              }
            })}
            onChange={(val) => setSelectItem(val)}
          />
        </Col>
        <Col md={4} sm={6} xs={2}>
          <br />
          <Button
            onClick={() => {
              onChoose({
                id: selectedItem,
                qty: 1,
                status: 1,
              })
            }}
            color="primary"
            disabled={!selectedItem}
          >
            Add
          </Button>
        </Col>
      </Row>
    </FormGroup>
  )
}
export default AddForm
