import { getData, sendData } from "./apiService.js";
export async function getDMs(visit_id) {
  let response = await getData(`visit_daily_monitorings/${visit_id}`);
  return response;
}
export async function getDM(id) {
  let response = await getData(`daily_monitorings/${id}`);
  return response;
}
export async function addDM(values) {
  return await sendData("daily_monitorings", values);
}
export async function editDM(values, id) {
  return await sendData(`daily_monitorings/${id}`, values, "PUT");
}

export async function deleteDM(id){
  return await sendData(`daily_monitorings/${id}`,{}, "DELETE");
}