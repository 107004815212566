import { getData, sendData } from './apiService.js'
export async function getPatients(params = {}) {
  let response = await getData('patients', params)
  return response
}

export async function getPatient(id) {
  let response = await getData(`patients/${id}`)
  return response
}

export async function addPatient(values) {
  return await sendData('patients', values)
}

export async function deletePatient(id) {
  return await sendData(`patients/${id}`, {}, 'DELETE')
}

export async function editPatient(values, id) {
  return await sendData(`patients/${id}`, values, 'PUT')
}
