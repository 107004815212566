import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from 'reactstrap'
import WhLayout from 'layouts/wh'
import DetailForm from 'components/item/detailForm'
import StockControl from 'components/item/stockControl'

function ItemStock() {
  let initalValues = {
    batch_number: '',
    expire_date: '',
  }
  const submitData = () => {}

  return (
    <WhLayout>
      <Formik
        initialValues={initalValues}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={(values) => submitData(values)}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DetailForm formik={formik} />
            <div class="text-center">
              <Button color="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-5">
        <StockControl />
      </div>
    </WhLayout>
  )
}
export default ItemStock
