import { Row, Col, Button, Input, Label } from 'reactstrap'
import { Trash } from 'react-bootstrap-icons'
import FormGroup from '../../layouts/formGroup'
import AddForm from './../AddForm'
import CustomSelect from '../../elements/customSelect'
import ItemContext from 'store/itemContext'
import CustomDatePicker from 'components/elements/customDatePicker'
import { useContext } from 'react'
import TownshipContext from 'store/townshipContext'

const RequestForm = ({
  selectItems,
  changeQty,
  choose,
  removeItem,
  nextTo,
  items,
  setInfo,
  info,
}) => {
  const { data } = useContext(ItemContext)
  const { data: townships } = useContext(TownshipContext)
  return (
    <>
      <AddForm
        onChoose={(selectedItem) => choose(selectedItem)}
        items={items}
      />
      <FormGroup title="Selected Items">
        {selectItems.map((item, index) => {
          return (
            <Row className="mb-2">
              <Col md={6} xs={7}>
                <br />
                <Input
                  value={`${data.find((val) => val.id === item.id)?.item} ${
                    data.find((val) => val.id === item.id)?.au
                  }`}
                  disabled
                />
              </Col>
              <Col md={3} xs={3}>
                <div style={{ textAlign: 'right' }}>
                  <span>( 55 )</span>
                </div>
                <Input
                  min="1"
                  type="number"
                  value={item.qty}
                  onChange={(e) => changeQty(index, e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value[0] === '0') changeQty(index, 1)
                  }}
                />
              </Col>
              <Col xs={2}>
                <br />
                <Button color="danger" onClick={() => removeItem(item.id)}>
                  <Trash />
                </Button>
              </Col>
            </Row>
          )
        })}
      </FormGroup>
      <FormGroup title="Info">
        <Row>
          <Col md="4" sm="6" xs="12">
            <Label>Township</Label>
            <CustomSelect
              options={townships?.map((element, index) => {
                return {
                  label: `${element.name}`,
                  value: element.id,
                }
              })}
              value={info.township_id}
              onChange={(value) =>
                setInfo({ ...info, township_id: value.value })
              }
            />
          </Col>
          <Col md="4" sm="6" xs="12">
            <Label>Date</Label>
            <CustomDatePicker
              name="date"
              value={info.date}
              onChange={(day) => setInfo({ ...info, date: day })}
            />
          </Col>
        </Row>
      </FormGroup>
      <Row className="mt-3">
        <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            onClick={nextTo}
            disabled={selectItems.length === 0}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  )
}
export default RequestForm
