import { useEffect, useContext, useState } from 'react'
import { Row, Col, Button, Input } from 'reactstrap'
import UserLayout from 'layouts/user'
import CustomSelect from 'components/elements/customSelect'
import CustomDatePicker from 'components/elements/customDatePicker'
import FormGroup from 'components/layouts/formGroup'

import {
  fullBloodCountOpt,
  liverFunctionOpt,
  rftOpt,
  otherXOpt,
  additionalOpt,
} from 'mock/labdata'
import LabContext from './../../../store/labContext'
import { useHistory } from 'react-router'

const LabDetail = (props) => {
  const {
    getEachData,
    updateData: updateLab,
    deleteData: deleteLab,
  } = useContext(LabContext)

  const [fullBloodCount, setFullBloodCount] = useState([])
  const [selectedFullBloodCount, setSelectedFullBloodCount] = useState(null)

  const [liverFunction, setLiverFunction] = useState([])
  const [selectedLiverFunction, setSelectedLiverFuncion] = useState(null)

  const [rft, setRft] = useState([])
  const [selectedRft, setSelectedRft] = useState(null)

  const [additional, setAdditonal] = useState([])
  const [selectedAdditional, setSelectedAditional] = useState(null)

  const [others, setOthers] = useState([])

  const [otherX, setOtherX] = useState([])
  const [selectedOtherX, setSelectedOtherX] = useState(null)
  const [date, setDate] = useState('')
  const [visitID, setVisitID] = useState('')
  const history = useHistory()

  const isAlreadyThere = (arr, value) => {
    let found = false
    arr.forEach((element) => {
      if (element.name === value) found = true
    })
    return found
  }

  const onDeleteData = async () => {
    if (await deleteLab(props.match.params.id)) {
      history.replace(`/user/lab/${visitID}`)
    }
  }

  const onSaveData = async () => {
    let data = {
      fullBloodCount: fullBloodCount,
      liverFunction: liverFunction,
      rft: rft,
      others: others,
      otherX: otherX,
      additional: additional,
    }
    let submitData = {
      body: data,
      date: date,
    }

    await updateLab(props.match.params.id, submitData)
  }

  useEffect(() => {
    async function getData() {
      const response = await getEachData(props.match.params.id)
      setDate(response.date)
      setFullBloodCount(response.body.fullBloodCount)
      setRft(response.body.rft)
      setLiverFunction(response.body.liverFunction)
      setOtherX(response.body.otherX)
      setOthers(response.body.others)
      setAdditonal(response.body.additional)
      setVisitID(response.visit_id)
    }
    getData()
  }, [])

  return (
    <UserLayout>
      <Row className="mt-3">
        <FormGroup title="Full Blood Count">
          <div className="d-flex justify-content-between">
            <div style={{ width: '70%' }}>
              <CustomSelect
                options={fullBloodCountOpt}
                onChange={(e) => {
                  setSelectedFullBloodCount(e.value)
                }}
              />
            </div>

            <Button
              color="primary"
              disabled={!selectedFullBloodCount}
              onClick={() => {
                if (
                  !isAlreadyThere(fullBloodCount, selectedFullBloodCount.name)
                ) {
                  setFullBloodCount((oldArray) => [
                    ...oldArray,
                    {
                      name: selectedFullBloodCount.name,
                      date: '',
                      result: '',
                      unit: selectedFullBloodCount.unit,
                    },
                  ])
                }
              }}
            >
              Add
            </Button>
          </div>

          {fullBloodCount.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input readOnly={true} defaultValue={element?.name} />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...fullBloodCount] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setFullBloodCount(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...fullBloodCount] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setFullBloodCount(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Unit</label>
                <Input readOnly={true} defaultValue={element?.unit} />
              </Col>
            </Row>
          ))}
        </FormGroup>
        <FormGroup title="Liver Function Test (LFT)">
          <div className="d-flex justify-content-between">
            <div style={{ width: '70%' }}>
              <CustomSelect
                options={liverFunctionOpt}
                onChange={(e) => {
                  setSelectedLiverFuncion(e.value)
                }}
              />
            </div>

            <Button
              color="primary"
              disabled={!selectedLiverFunction}
              onClick={() => {
                if (
                  !isAlreadyThere(liverFunction, selectedLiverFunction.name)
                ) {
                  setLiverFunction((oldArray) => [
                    ...oldArray,
                    {
                      name: selectedLiverFunction.name,
                      date: '',
                      result: '',
                      unit: selectedLiverFunction.unit,
                    },
                  ])
                }
              }}
            >
              Add
            </Button>
          </div>

          {liverFunction.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input readOnly={true} defaultValue={element?.name} />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...liverFunction] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setLiverFunction(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...liverFunction] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setLiverFunction(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Unit</label>
                <Input readOnly={true} defaultValue={element?.unit} />
              </Col>
            </Row>
          ))}
        </FormGroup>
        <FormGroup title="Renal Function Test (RFT)">
          <div className="d-flex justify-content-between">
            <div style={{ width: '70%' }}>
              <CustomSelect
                options={rftOpt}
                onChange={(e) => {
                  setSelectedRft(e.value)
                }}
              />
            </div>

            <Button
              color="primary"
              disabled={!selectedRft}
              onClick={() => {
                if (!isAlreadyThere(rft, selectedRft.name)) {
                  setRft((oldArray) => [
                    ...oldArray,
                    {
                      name: selectedRft.name,
                      date: '',
                      result: '',
                      chooseUnit: selectedRft.chooseUnit || false,
                      unit: selectedRft.unit,
                    },
                  ])
                }
              }}
            >
              Add
            </Button>
          </div>

          {rft.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input readOnly={true} defaultValue={element?.name} />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...rft] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setRft(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...rft] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setRft(newArr)
                  }}
                />
              </Col>
              {!element.chooseUnit && (
                <Col md="3">
                  <label>Unit</label>
                  <Input
                    readOnly={true}
                    defaultValue={element?.unit}
                    value={element.unit}
                  />
                </Col>
              )}
              {element.chooseUnit && (
                <Col md="3">
                  <label>Unit</label>
                  <select
                    value={element.unit}
                    className="form-control"
                    onChange={(e) => {
                      let newArr = [...rft] // copying the old datas array
                      newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                      setRft(newArr)
                    }}
                  >
                    <option></option>
                    {element.units.map((value) => (
                      <option>{value}</option>
                    ))}
                  </select>
                </Col>
              )}
            </Row>
          ))}
        </FormGroup>
        <FormGroup title="Additional">
          <div className="d-flex justify-content-between">
            <div style={{ width: '70%' }}>
              <CustomSelect
                options={additionalOpt}
                onChange={(e) => {
                  setSelectedAditional(e.value)
                }}
              />
            </div>

            <Button
              color="primary"
              disabled={!selectedAdditional}
              onClick={() => {
                if (!isAlreadyThere(additional, selectedAdditional.name)) {
                  setAdditonal((oldArray) => [
                    ...oldArray,
                    {
                      name: selectedAdditional.name,
                      date: '',
                      result: '',
                      chooseUnit: selectedAdditional.chooseUnit || false,
                      unit: selectedAdditional.unit,
                    },
                  ])
                }
              }}
            >
              Add
            </Button>
          </div>

          {additional.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input readOnly={true} defaultValue={element?.name} />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...additional] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setSelectedAditional(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...additional] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setSelectedAditional(newArr)
                  }}
                />
              </Col>
              {!element.chooseUnit && (
                <Col md="3">
                  <label>Unit</label>
                  <Input readOnly={true} defaultValue={element?.unit} />
                </Col>
              )}
              {element.chooseUnit && (
                <Col md="3">
                  <label>Unit</label>
                  <select
                    value={element.unit}
                    className="form-control"
                    onChange={(e) => {
                      let newArr = [...additional] // copying the old datas array
                      newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                      setAdditonal(newArr)
                    }}
                  >
                    <option></option>
                    {element?.units?.map((value) => (
                      <option>{value}</option>
                    ))}
                  </select>
                </Col>
              )}
            </Row>
          ))}
        </FormGroup>
        <FormGroup title="Others">
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              onClick={() => {
                setOthers((oldArray) => [
                  ...oldArray,
                  {
                    name: '',
                    date: '',
                    result: '',
                    unit: '',
                  },
                ])
              }}
            >
              Add
            </Button>
          </div>
          {others.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input
                  value={element.name}
                  onChange={(e) => {
                    let newArr = [...others] // copying the old datas array
                    newArr[index].name = e.target.value // replace e.target.value with whatever you want to change it to
                    setOthers(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...others] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setOthers(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  type="number"
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...others] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setOthers(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Unit</label>
                <Input
                  value={element.unit}
                  onChange={(e) => {
                    let newArr = [...others] // copying the old datas array
                    newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                    setOthers(newArr)
                  }}
                />
              </Col>
            </Row>
          ))}
        </FormGroup>

        <FormGroup title="Other (X-ray, ultrasound,CT)">
          <div className="d-flex justify-content-between">
            <div style={{ width: '70%' }}>
              <CustomSelect
                options={otherXOpt}
                onChange={(e) => {
                  setSelectedOtherX(e.value)
                }}
              />
            </div>

            <Button
              color="primary"
              disabled={!selectedOtherX}
              onClick={() => {
                if (!isAlreadyThere(otherX, selectedOtherX.name)) {
                  setOtherX((oldArray) => [
                    ...oldArray,
                    {
                      name: selectedOtherX.name,
                      date: '',
                      result: '',
                    },
                  ])
                }
              }}
            >
              Add
            </Button>
          </div>

          {otherX.map((element, index) => (
            <Row className="mt-3 mb-3">
              <Col md="3">
                <label>Name</label>
                <Input readOnly={true} defaultValue={element?.name} />
              </Col>
              <Col md="3">
                <label>Date</label>
                <CustomDatePicker
                  value={element.date}
                  onChange={(value) => {
                    let newArr = [...otherX] // copying the old datas array
                    newArr[index].date = value // replace e.target.value with whatever you want to change it to
                    setOtherX(newArr)
                  }}
                />
              </Col>
              <Col md="3">
                <label>Result</label>
                <Input
                  value={element.result}
                  onChange={(e) => {
                    let newArr = [...otherX] // copying the old datas array
                    newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                    setOtherX(newArr)
                  }}
                />
              </Col>
            </Row>
          ))}
        </FormGroup>
        <FormGroup title="Info">
          <Col md="4">
            <label>Date</label>
            <CustomDatePicker
              value={date}
              onChange={(value) => {
                setDate(value)
              }}
            />
          </Col>
        </FormGroup>
        <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            onClick={onSaveData}
          >
            Save
          </Button>
          <Button
            className="submit-btn mb-3"
            color="danger"
            onClick={onDeleteData}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </UserLayout>
  )
}
export default LabDetail
