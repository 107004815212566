import { useEffect, useState, useContext } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import WhLayout from 'layouts/wh'
import ItemList from 'components/item/itemList'
import ItemContext from 'store/itemContext'
const ItemListPage = () => {
  const [edit, isEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const itemCtx = useContext(ItemContext)

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      await itemCtx.getData()
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <WhLayout>
      {!loading && (
        <>
          <Row className="mb-3">
            <Col xs="6">
              <h5>Item Lists</h5>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Link to="/wh/new-item">
                <Button color="primary">New Item</Button>
              </Link>
            </Col>
          </Row>

          <ItemList editClick={() => isEdit(true)} />
        </>
      )}
    </WhLayout>
  )
}
export default ItemListPage
