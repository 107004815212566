import { getData, sendData } from './apiService.js'
export async function getTownshipStores(params = {}) {
  let response = await getData(`townshipstores`, params)
  return response
}
export async function getTownshipStore(id) {
  let response = await getData(`townshipstores/${id}`)
  return response
}
export async function addTownshipStore(values) {
  return await sendData('townshipstores', values)
}
export async function editTownshipStore(values, id) {
  return await sendData(`townshipstores/${id}`, values, 'PUT')
}

export async function deleteTownshipStore(id) {
  return await sendData(`townshipstores/${id}`, {}, 'DELETE')
}
