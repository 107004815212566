import { Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { authRoutes } from 'routes/auth'
import { userRoutes } from 'routes/userRoutes'
import { whRoutes } from 'routes/whRoutes'
import UserProtectedRoute from 'routes/protectedRoutes/userProtectedRoute.js'
import WhProtectedRoute from 'routes/protectedRoutes/whProtectedRoute.js'
import NotFound from './pages/notFound.js'
import './App.scss'
import { adminRoutes } from './routes/adminRoutes.js'
import AdminProtectedRoute from './routes/protectedRoutes/adminProtectedRoute.js'

function App() {
  return (
    <>
      <Switch>
        {authRoutes.map((route) => {
          return <Route path={route.path} exact component={route.component} />
        })}
        {userRoutes.map((route) => {
          return (
            <UserProtectedRoute
              path={'/user' + route.path}
              exact
              component={route.component}
            />
          )
        })}
        {adminRoutes.map((route) => {
          return (
            <AdminProtectedRoute
              path={'/admin' + route.path}
              exact
              component={route.component}
            />
          )
        })}
        {whRoutes.map((route) => {
          return (
            <WhProtectedRoute
              path={'/wh' + route.path}
              exact
              component={route.component}
            />
          )
        })}
        <Route path="" component={NotFound} />
      </Switch>
      <NotificationContainer />
    </>
  )
}
export default App
