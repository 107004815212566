import { Row, Col, Input, Label } from 'reactstrap'
import DatePicker from 'reactstrap-date-picker'
import FormGroup from '../layouts/formGroup'
import CustomSelect from '../elements/customSelect'
import CustomDatePicker from '../elements/customDatePicker'
import SymptonContext from 'store/symptonContext'
import { finalClassificationOpt } from 'mock/patient-form-options'

const InfoForm = ({ isEdit = false, formik }) => {
  return (
    <FormGroup title="Visit Info">
      <Row>
        <Col md={3} sm={6} xs={12} className="form-group-item">
          <Label> Date *</Label>
          <CustomDatePicker
            name="visit.date"
            value={formik.values.visit.date}
            onChange={(day) => formik.setFieldValue('visit.date', day)}
          />
        </Col>
        <Col md={3} sm={6} xs={12} className="form-group-item">
          <Label>Onset Symptons *</Label>
          <SymptonContext.Consumer>
            {({ data }) => (
              <CustomSelect
                multi={true}
                options={data.map((element) => {
                  return {
                    value: element.name.toLowerCase(),
                    label: element.name,
                  }
                })}
                onChange={(value) =>
                  formik.setFieldValue('visit.onset_symptons', value)
                }
                value={formik.values.visit.onset_symptons}
              />
            )}
          </SymptonContext.Consumer>
        </Col>
        <Col md={3} sm={6} xs={12} className="form-group-item">
          <Label>Onset Sympton Other</Label>
          <Input
            name="visit.onset_symptons_other"
            onChange={formik.handleChange}
            value={formik.values.visit.onset_symptons_other}
            disabled={!formik.values.visit.onset_symptons.includes('others')}
          />
        </Col>
        <Col md={3} sm={6} xs={12} className="form-group-item">
          <Label>Onset Sympton Date *</Label>
          <CustomDatePicker
            name="visit.onset_symptons_date"
            value={formik.values.visit.onset_symptons_date}
            onChange={(day) =>
              formik.setFieldValue('visit.onset_symptons_date', day)
            }
          />
        </Col>
      </Row>
      {isEdit && (
        <Row>
          <Col md={3} sm={6} xs={12} className="form-group-item">
            <Label>Outcome</Label>
            <CustomSelect
              onChange={({ value }) => {
                formik.setFieldValue('visit.outcome', value)
              }}
              options={[
                { label: 'Cured', value: 'Cured' },
                { label: 'LOF', value: 'LOF' },
                { label: 'Died', value: 'Died' },
                {
                  label: 'Transfer to Hospital/Tx Center',
                  value: 'Transfer to Hospital/Tx Center',
                },
                { label: 'Other', value: 'Other' },
                { label: 'Recovered Symptoms', value: 'Recovered Symptoms' },
              ]}
              value={formik.values.visit.outcome}
            />
          </Col>
          <Col md={3} sm={6} xs={12} className="form-group-item">
            <Label>Outcome Remark</Label>
            <Input
              name="visit.outcome_remark"
              onChange={formik.handleChange}
              value={formik.values.visit.outcome_remark}
            />
          </Col>
          <Col md={3} sm={6} xs={12} className="form-group-item">
            <Label>Outcome Date</Label>
            <CustomDatePicker
              name="visit.outcome_date"
              value={formik.values.visit.outcome_date}
              onChange={(day) =>
                formik.setFieldValue('visit.outcome_date', day)
              }
            />
          </Col>
          <Col md={3} sm={6} xs={12} className="form-group-item">
            <Label>Service Type</Label>
            <Input
              name="visit.service_type"
              onChange={formik.handleChange}
              value={formik.values.visit.service_type}
            />
          </Col>
          <Col md={3} sm={6} xs={12} className="form-group-item">
            <Label>Treatment Center Name</Label>
            <Input
              onChange={formik.handleChange}
              name="visit.treatment_center_name"
              value={formik.values.visit.treatment_center_name}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Final Classification</Label>
            <Input
              onChange={formik.handleChange}
              name="visit.final"
              value={formik.values.visit.final}
            />
          </Col>
        </Row>
      )}
    </FormGroup>
  )
}
export default InfoForm
