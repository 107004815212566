import { Row, Col, Input, Label } from 'reactstrap'
import { Editor } from 'react-draft-wysiwyg'
import { useContext, useEffect } from 'react'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import FormGroup from '../layouts/formGroup'
import CustomSelect from '../elements/customSelect'
import CustomDatePicker from '../elements/customDatePicker'
import CustomTimePicker from '../elements/customTimePicker'
import SymptonContext from 'store/symptonContext'
import HomeBasedCareContext from '../../store/homeBasedCareContext'
import PhysicianContext from '../../store/physicianContext'
import {
  filledByOpt,
  serviceTypeOpt,
  temperateUnitOpt,
  finalClassificationOpt,
} from 'mock/patient-form-options'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './inputForm.scss'
const DMInputForm = ({ formik, title }) => {
  const { getData: getHomeBasedCares, data: homeBasedCares } =
    useContext(HomeBasedCareContext)
  const { getData: getPhysicians, data: physicians } =
    useContext(PhysicianContext)
  useEffect(() => {
    async function fetchData() {
      await getHomeBasedCares()
      await getPhysicians()
    }
    fetchData()
  }, [])
  return (
    <FormGroup title={title}>
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label> Date *</Label>
          <CustomDatePicker
            name="daily_monitoring.date"
            value={formik.values.daily_monitoring.date}
            onChange={(day) =>
              formik.setFieldValue('daily_monitoring.date', day)
            }
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label> Time *</Label>
          <CustomTimePicker
            onValueChange={(val) =>
              formik.setFieldValue('daily_monitoring.time', val)
            }
            defaultValue={formik.values.daily_monitoring.time}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Can Contact</Label>
          <CustomSelect
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
            onChange={({ value }) =>
              formik.setFieldValue('daily_monitoring.contact', value)
            }
            value={formik.values.daily_monitoring.contact}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Delegate to</Label>
          <CustomSelect
            options={filledByOpt}
            onChange={({ value }) =>
              formik.setFieldValue('daily_monitoring.refer_to', value)
            }
            value={formik.values.daily_monitoring.refer_to}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Daily Symptoms</Label>
          <SymptonContext.Consumer>
            {({ data }) => (
              <CustomSelect
                multi={true}
                options={data.map((element) => {
                  return {
                    value: element.name.toLowerCase(),
                    label: element.name,
                  }
                })}
                onChange={(value) =>
                  formik.setFieldValue('daily_monitoring.daily_symptons', value)
                }
                value={formik.values.daily_monitoring.daily_symptons}
                isDisabled={formik.values.daily_monitoring.contact !== 'yes'}
              />
            )}
          </SymptonContext.Consumer>
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Daily Symptoms Other</Label>
          <Input
            name="daily_monitoring.daily_symptons_other"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.daily_symptons_other}
            disabled={
              !formik.values.daily_monitoring.daily_symptons?.includes('others')
            }
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>SPO 2</Label>
          <Input
            name="daily_monitoring.spo2"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.spo2}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Respiratory Rate</Label>
          <Input
            name="daily_monitoring.respiratory_rate"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.respiratory_rate}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Heart Rate</Label>
          <Input
            name="daily_monitoring.heart_rate"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.heart_rate}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Temperature</Label>
          <Input
            name="daily_monitoring.temperature"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.temperature}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Temperature Unit</Label>
          <CustomSelect
            options={temperateUnitOpt}
            onChange={({ value }) =>
              formik.setFieldValue('daily_monitoring.temperature_unit', value)
            }
            value={formik.values.daily_monitoring.temperature_unit}
            isDisabled={formik.values.daily_monitoring.contact !== 'yes'}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>RBS (mg/dl)</Label>
          <Input
            name="daily_monitoring.rbs"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.rbs}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>FBS (mg/dl)</Label>
          <Input
            name="daily_monitoring.fbs"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.fbs}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>2HPP (mg/dl)</Label>
          <Input
            name="daily_monitoring.hpp"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.hpp}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Systolic Blood Pressure (mmHg)</Label>
          <Input
            name="daily_monitoring.systolic_blood_pressure"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.systolic_blood_pressure}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Diastolic Blood Pressure (mmHg)</Label>
          <Input
            name="daily_monitoring.diastolic_blood_pressure"
            onChange={formik.handleChange}
            value={formik.values.daily_monitoring.diastolic_blood_pressure}
            disabled={formik.values.daily_monitoring.contact !== 'yes'}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Service Type</Label>
          <CustomSelect
            options={serviceTypeOpt}
            onChange={({ value }) =>
              formik.setFieldValue('daily_monitoring.service_type', value)
            }
            value={formik.values.daily_monitoring.service_type}
            isDisabled={formik.values.daily_monitoring.contact !== 'yes'}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          {formik.values.daily_monitoring.service_type?.includes(
            'hospital/treatment_center'
          ) && (
            <>
              <Label>Treatment Center </Label>
              <Input
                name="daily_monitoring.treatment_center_name"
                onChange={formik.handleChange}
                value={formik.values.daily_monitoring.treatment_center_name}
              />
            </>
          )}
          {formik.values.daily_monitoring.service_type?.includes(
            'physician'
          ) && (
            <>
              <Label>Physician </Label>
              <CustomSelect
                options={physicians?.map((element) => {
                  return {
                    value: element.id,
                    label: element.name,
                  }
                })}
                onChange={({ value }) =>
                  formik.setFieldValue('daily_monitoring.physician_id', value)
                }
                value={formik.values.daily_monitoring.physician_id}
              />
            </>
          )}
          {formik.values.daily_monitoring.service_type?.includes(
            'homebased_care_team'
          ) && (
            <>
              <Label>Homebased Care Team </Label>
              <CustomSelect
                options={homeBasedCares?.map((element) => {
                  return {
                    value: element.id,
                    label: element.name,
                  }
                })}
                onChange={({ value }) =>
                  formik.setFieldValue(
                    'daily_monitoring.home_based_care_id',
                    value
                  )
                }
                value={formik.values.daily_monitoring.home_based_care_id}
              />
            </>
          )}
        </Col>

        <Col md={8} sm={6} xs={12} className="form-group-item  editor-main">
          <Label>Treatment Note</Label>
          <Editor
            toolbarClassName="editor-toolbar"
            wrapperClassName="editor-wrapper"
            editorClassName="editor"
            toolbar={{ options: ['list'] }}
            name="daily_monitoring.treatment_note"
            defaultEditorState={formik.values.daily_monitoring.treatment_note}
            onEditorStateChange={(editorState) => {
              const rawContentState = convertToRaw(
                editorState.getCurrentContent()
              )
              const markup = draftToHtml(rawContentState)
              formik.setFieldValue('daily_monitoring.treatment_note', markup)
            }}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export default DMInputForm
