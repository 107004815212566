import { useContext, useState } from 'react'
import { Col, Row, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import UserLayout from 'layouts/user'
import CustomSelect from 'components/elements/customSelect'
import Item from 'components/item-request/item'
import { itemRequestStatus } from 'mock/item'
import ItemRequestContext from 'store/itemRequestContext'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import AuthContext from 'store/authContext'
import './index.scss'
const ItemRequest = () => {
  const [loading, setLoading] = useState(false)
  const { data: user } = useContext(AuthContext)
  const { getData: getIssues } = useContext(WarehouseIssueContext)

  useState(() => {
    async function fetchApi() {
      setLoading(true)
      await getIssues({ township_id: user.township_id })
      setLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <>
        <Row>
          <Col xs="6">
            <h5>Item Requests</h5>
          </Col>
          <Col xs={{ size: 6 }} style={{ textAlign: 'right' }}>
            <Button color="primary" outline>
              View All
            </Button>
            <Link to={`/user/new-item-request`}>
              <Button color="primary" outline>
                Add New
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className="mt-3">
          <CustomSelect options={itemRequestStatus} />
        </Row>
        <Row className="item-request-container">
          <WarehouseIssueContext.Consumer>
            {({ data }) =>
              data.map((element, index) => {
                return (
                  <Link to={`/user/item-request-review/${element.id}`}>
                    <Item element={element} index={index} />
                  </Link>
                )
              })
            }
          </WarehouseIssueContext.Consumer>
        </Row>
      </>
    </UserLayout>
  )
}
export default ItemRequest
