import DayPickerInput from 'react-day-picker/DayPickerInput'
import { convertToServerFormat } from 'lib/dateFormat'
import './custom-datepicker.scss'
const CustomDatePicker = ({ name, value, onChange, disabled }) => {
  return (
    <div className="datepicker-container">
      <DayPickerInput
        name={name}
        value={value}
        onDayChange={(day) => onChange(convertToServerFormat(day))}
        placeholder="dd/mm/yyyy"
        inputProps={{ disabled: disabled }}
      />
    </div>
  )
}
export default CustomDatePicker
