import { useContext, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useParams } from 'react-router-dom'
import UserLayout from 'layouts/user'
import ReviewPage from 'components/item-request/reivewPage'
import ItemRequestContext from 'store/itemRequestContext'
import LoadingContext from 'store/loadingContext'
import ItemContext from 'store/itemContext'

const ItemRequestReview = () => {
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)
  const itemReqCtx = useContext(ItemRequestContext)
  const itemCtx = useContext(ItemContext)
  const { id } = useParams()
  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await itemCtx.getData()
      await itemReqCtx.getEachData(id)
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <Card>
        <CardBody>
          <ReviewPage
            showActions={false}
            selectItems={itemReqCtx?.eachData?.items}
            approvedItems={itemReqCtx?.eachData?.approved_items}
            info={{
              status: itemReqCtx.eachData?.status,
              daily_monitoring: itemReqCtx.eachData?.daily_monitoring,
              created_at: itemReqCtx.eachData?.created_at?.substr(0, 10),
              remark: itemReqCtx.eachData?.remark,
            }}
          />
        </CardBody>
      </Card>
    </UserLayout>
  )
}
export default ItemRequestReview
