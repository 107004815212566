import { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { loginReq } from '../api/auth'
import { getCache, removeCache } from '../lib/cache'

const AuthContext = createContext({
  data: {},
  login: () => {},
  logout: () => {},
  loading: false,
})

export function AuthContextProvider(props) {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(JSON.parse(getCache('userData')))
  const history = useHistory()

  const context = {
    loading: loading,
    data: user,
    login: async (values) => {
      setLoading(true)
      let response = await loginReq(values)
      if (response) {
        setUser(response)

        if (response.role == 'md' || response.role == 'dmt') {
          history.push('/user')
        } else if (response.role == 'warehouse') {
          history.push('/wh')
        } else if (response.role == 'admin') {
          history.push('/admin')
        }
      }
      setLoading(false)
    },
    logout: () => {
      removeCache('token')
      removeCache('userData')
      history.push('/')
    },
  }
  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
