import { useState, useContext } from 'react'
import { Row, Col, Button, Input } from 'reactstrap'
import AdminLayout from 'layouts/admin'
import CustomSelect from 'components/elements/customSelect'
import CustomDatePicker from 'components/elements/customDatePicker'
import FormGroup from 'components/layouts/formGroup'
import { getTodayDate } from '../../../lib/dateFormat'
import {
  fullBloodCountOpt,
  liverFunctionOpt,
  rftOpt,
  otherXOpt,
  additionalOpt,
} from 'mock/labdata'
import LabContext from '../../../store/labContext'

const NewLab = (props) => {
  const { addData: newLab } = useContext(LabContext)
  const [fullBloodCount, setFullBloodCount] = useState([])
  const [selectedFullBloodCount, setSelectedFullBloodCount] = useState(null)

  const [liverFunction, setLiverFunction] = useState([])
  const [selectedLiverFunction, setSelectedLiverFuncion] = useState(null)

  const [rft, setRft] = useState([])
  const [selectedRft, setSelectedRft] = useState(null)

  const [additional, setAdditonal] = useState([])
  const [selectedAdditional, setSelectedAditional] = useState(null)

  const [others, setOthers] = useState([])

  const [otherX, setOtherX] = useState([])
  const [selectedOtherX, setSelectedOtherX] = useState(null)
  const [date, setDate] = useState(getTodayDate())

  const isAlreadyThere = (arr, value) => {
    let found = false
    arr.forEach((element) => {
      if (element.name === value) found = true
    })
    return found
  }

  const onSaveData = async () => {
    let data = {
      fullBloodCount: fullBloodCount,
      liverFunction: liverFunction,
      rft: rft,
      others: others,
      otherX: otherX,
      additional: additional,
    }
    let submitData = {
      body: data,
      visit_id: props.match.params.visit_id,
      date: date,
    }

    await newLab(submitData)
  }

  return (
    <AdminLayout>
      <FormGroup title="Full Blood Count">
        <div className="d-flex justify-content-between">
          <div style={{ width: '70%' }}>
            <CustomSelect
              options={fullBloodCountOpt}
              onChange={(e) => {
                setSelectedFullBloodCount(e.value)
              }}
            />
          </div>

          <Button
            color="primary"
            disabled={!selectedFullBloodCount}
            onClick={() => {
              if (
                !isAlreadyThere(fullBloodCount, selectedFullBloodCount.name)
              ) {
                setFullBloodCount((oldArray) => [
                  ...oldArray,
                  {
                    name: selectedFullBloodCount.name,
                    date: '',
                    result: '',
                    unit: selectedFullBloodCount.unit,
                  },
                ])
              }
            }}
          >
            Add
          </Button>
        </div>

        {fullBloodCount.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input readOnly={true} defaultValue={element?.name} />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                onChange={(value) => {
                  let newArr = [...fullBloodCount] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setFullBloodCount(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                onChange={(e) => {
                  let newArr = [...fullBloodCount] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setFullBloodCount(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Unit</label>
              <Input readOnly={true} defaultValue={element?.unit} />
            </Col>
          </Row>
        ))}
      </FormGroup>
      <FormGroup title="Liver Function Test (LFT)">
        <div className="d-flex justify-content-between">
          <div style={{ width: '70%' }}>
            <CustomSelect
              options={liverFunctionOpt}
              onChange={(e) => {
                setSelectedLiverFuncion(e.value)
              }}
            />
          </div>

          <Button
            color="primary"
            disabled={!selectedLiverFunction}
            onClick={() => {
              if (!isAlreadyThere(liverFunction, selectedLiverFunction.name)) {
                setLiverFunction((oldArray) => [
                  ...oldArray,
                  {
                    name: selectedLiverFunction.name,
                    date: '',
                    result: '',
                    unit: selectedLiverFunction.unit,
                  },
                ])
              }
            }}
          >
            Add
          </Button>
        </div>

        {liverFunction.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input readOnly={true} defaultValue={element?.name} />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                onChange={(value) => {
                  let newArr = [...liverFunction] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setLiverFunction(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                onChange={(e) => {
                  let newArr = [...liverFunction] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setLiverFunction(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Unit</label>
              <Input readOnly={true} defaultValue={element?.unit} />
            </Col>
          </Row>
        ))}
      </FormGroup>
      <FormGroup title="Renal Function Test (RFT)">
        <div className="d-flex justify-content-between">
          <div style={{ width: '70%' }}>
            <CustomSelect
              options={rftOpt}
              onChange={(e) => {
                setSelectedRft(e.value)
              }}
            />
          </div>

          <Button
            color="primary"
            disabled={!selectedRft}
            onClick={() => {
              if (!isAlreadyThere(rft, selectedRft.name)) {
                setRft((oldArray) => [
                  ...oldArray,
                  {
                    name: selectedRft.name,
                    date: '',
                    result: '',
                    chooseUnit: selectedRft.chooseUnit || false,
                    unit: selectedRft.unit,
                    units: selectedRft.units,
                  },
                ])
              }
            }}
          >
            Add
          </Button>
        </div>

        {rft.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input readOnly={true} defaultValue={element?.name} />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                onChange={(value) => {
                  let newArr = [...rft] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setRft(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                onChange={(e) => {
                  let newArr = [...rft] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setRft(newArr)
                }}
              />
            </Col>
            {!element.chooseUnit && (
              <Col md="3">
                <label>Unit</label>
                <Input readOnly={true} defaultValue={element?.unit} />
              </Col>
            )}
            {element.chooseUnit && (
              <Col md="3">
                <label>Unit</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    let newArr = [...rft] // copying the old datas array
                    newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                    setRft(newArr)
                  }}
                >
                  <option></option>
                  {element?.units?.map((value) => (
                    <option>{value}</option>
                  ))}
                </select>
              </Col>
            )}
          </Row>
        ))}
      </FormGroup>
      <FormGroup title="Additional">
        <div className="d-flex justify-content-between">
          <div style={{ width: '70%' }}>
            <CustomSelect
              options={additionalOpt}
              onChange={(e) => {
                setSelectedAditional(e.value)
              }}
            />
          </div>

          <Button
            color="primary"
            disabled={!selectedAdditional}
            onClick={() => {
              if (!isAlreadyThere(rft, selectedAdditional.name)) {
                setAdditonal((oldArray) => [
                  ...oldArray,
                  {
                    name: selectedAdditional.name,
                    date: '',
                    result: '',
                    chooseUnit: selectedAdditional.chooseUnit || false,
                    unit: selectedAdditional.unit,
                    units: selectedAdditional.units,
                  },
                ])
              }
            }}
          >
            Add
          </Button>
        </div>

        {additional.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input readOnly={true} defaultValue={element?.name} />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                onChange={(value) => {
                  let newArr = [...additional] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setSelectedAditional(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                onChange={(e) => {
                  let newArr = [...additional] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setSelectedAditional(newArr)
                }}
              />
            </Col>
            {!element.chooseUnit && (
              <Col md="3">
                <label>Unit</label>
                <Input readOnly={true} defaultValue={element?.unit} />
              </Col>
            )}
            {element.chooseUnit && (
              <Col md="3">
                <label>Unit</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    let newArr = [...additional] // copying the old datas array
                    newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                    setAdditonal(newArr)
                  }}
                >
                  <option></option>
                  {element.units.map((value) => (
                    <option>{value}</option>
                  ))}
                </select>
              </Col>
            )}
          </Row>
        ))}
      </FormGroup>
      <FormGroup title="Others">
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            onClick={() => {
              setOthers((oldArray) => [
                ...oldArray,
                {
                  name: '',
                  date: '',
                  result: '',
                  unit: '',
                },
              ])
            }}
          >
            Add
          </Button>
        </div>
        {others.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input
                value={element.name}
                onChange={(e) => {
                  let newArr = [...others] // copying the old datas array
                  newArr[index].name = e.target.value // replace e.target.value with whatever you want to change it to
                  setOthers(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                value={element.date}
                onChange={(value) => {
                  let newArr = [...others] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setOthers(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                type="number"
                onChange={(e) => {
                  let newArr = [...others] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setOthers(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Unit</label>
              <Input
                value={element.unit}
                onChange={(e) => {
                  let newArr = [...others] // copying the old datas array
                  newArr[index].unit = e.target.value // replace e.target.value with whatever you want to change it to
                  setOthers(newArr)
                }}
              />
            </Col>
          </Row>
        ))}
      </FormGroup>

      <FormGroup title="Other (X-ray, ultrasound,CT)">
        <div className="d-flex justify-content-between">
          <div style={{ width: '70%' }}>
            <CustomSelect
              options={otherXOpt}
              onChange={(e) => {
                setSelectedOtherX(e.value)
              }}
            />
          </div>

          <Button
            color="primary"
            disabled={!selectedOtherX}
            onClick={() => {
              if (!isAlreadyThere(rft, selectedOtherX.name)) {
                setOtherX((oldArray) => [
                  ...oldArray,
                  {
                    name: selectedOtherX.name,
                    date: '',
                    result: '',
                  },
                ])
              }
            }}
          >
            Add
          </Button>
        </div>

        {otherX.map((element, index) => (
          <Row className="mt-3 mb-3">
            <Col md="3">
              <label>Name</label>
              <Input readOnly={true} defaultValue={element?.name} />
            </Col>
            <Col md="3">
              <label>Date</label>
              <CustomDatePicker
                onChange={(value) => {
                  let newArr = [...otherX] // copying the old datas array
                  newArr[index].date = value // replace e.target.value with whatever you want to change it to
                  setOtherX(newArr)
                }}
              />
            </Col>
            <Col md="3">
              <label>Result</label>
              <Input
                onChange={(e) => {
                  let newArr = [...otherX] // copying the old datas array
                  newArr[index].result = e.target.value // replace e.target.value with whatever you want to change it to
                  setOtherX(newArr)
                }}
              />
            </Col>
          </Row>
        ))}
      </FormGroup>
      <FormGroup title="Info">
        <Col md="4">
          <label>Date</label>
          <CustomDatePicker
            value={date}
            onChange={(value) => {
              setDate(value)
            }}
          />
        </Col>
      </FormGroup>
      <div className="d-flex justify-content-center">
        <Button color="primary" onClick={onSaveData}>
          Save
        </Button>
      </div>
    </AdminLayout>
  )
}
export default NewLab
