import { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, Button } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import UserLayout from 'layouts/user'
import WhItemManager from 'components/item-request/wh/itemManager'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import ItemContext from 'store/itemContext'
import LoadingContext from 'store/loadingContext'
import AddForm from 'components/item-request/AddForm'

const EditItemRequest = () => {
  let { id } = useParams()
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)
  const { getEachData: getIssue, eachData: issue } = useContext(
    WarehouseIssueContext
  )
  const { data: itemLists, getData: getItems } = useContext(ItemContext)
  const [items, setItem] = useState([])
  let history = useHistory()

  const removeItem = (id) => {
    setItem(items.filter((item) => item.id !== id))
  }
  const changeQty = (index, value) => {
    setItem(
      items.map((sel, i) => {
        if (i === index) sel.qty = value
        return sel
      })
    )
  }
  const choose = (selectedItem) => {
    if (!items.find((val) => val.id === selectedItem.id))
      setItem((oldArray) => [...oldArray, selectedItem])
  }
  const onSubmit = () => {
    addActionLoading(true)
    history.replace(`/user/item-request-review/${id}`)
    addActionLoading(false)
  }

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await getItems()
      let reponse = await getIssue(id)
      let items = reponse.warehouse_issued_items.map((element) => {
        return {
          id: element.item_id,
          qty: element.quantity,
        }
      })
      setItem(items)
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <Row>
        <Col xs="6">
          <h5>Edit Request</h5>
        </Col>
      </Row>
      <Card className="mb-5">
        <CardBody>
          <AddForm
            onChoose={(selectedItem) => choose(selectedItem)}
            items={itemLists}
          />
          <WhItemManager
            removeItem={removeItem}
            changeQty={changeQty}
            items={items}
            status={issue?.status}
          />
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            onClick={onSubmit}
            disabled={actionLoading}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </UserLayout>
  )
}
export default EditItemRequest
