import { getData, sendData } from "./apiService.js";
export async function getItems() {
  let response = await getData(`items`);
  return response;
}
export async function getItem(id) {
  let response = await getData(`items/${id}`);
  return response;
}
export async function addItem(values) {
  return await sendData("items", values);
}
export async function editItem(values, id) {
  return await sendData(`items/${id}`, values, "PUT");
}

export async function deleteItem(id) {
  return await sendData(`items/${id}`, {}, "DELETE");
}
