import { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, Button, Input, Label } from 'reactstrap'
import { useParams } from 'react-router-dom'
import WhLayout from 'layouts/wh'
import Info from 'components/item-request/info'
import FormGroup from 'components/layouts/formGroup'
import WhItemManager from 'components/item-request/wh/itemManager'
import CustomDatePicker from 'components/elements/customDatePicker'
import LoadingContext from 'store/loadingContext'
import ItemContext from 'store/itemContext'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import { getTodayDate } from 'lib/dateFormat'
import './index.scss'
const RequestDetail = () => {
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)
  const itemCtx = useContext(ItemContext)
  const {
    getEachData: getIssue,
    eachData: issue,
    editData: updateIssue,
  } = useContext(WarehouseIssueContext)
  const [items, setItems] = useState([])
  const [date, setDate] = useState(getTodayDate())
  const [remark, setRemark] = useState('')
  const { id } = useParams()
  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await itemCtx.getData()
      let response = await getIssue(id)
      let items = response.warehouse_issued_items.map((element) => {
        return {
          id: element.item_id,
          qty: element.quantity,
        }
      })
      setRemark(response.remark)
      setItems(items)
      addLoading(false)
    }
    fetchApi()
  }, [])

  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id))
  }
  const changeQty = (index, value) => {
    setItems(
      items.map((sel, i) => {
        if (i === index) sel.qty = value
        return sel
      })
    )
  }
  const submit = async (status) => {
    let issue_items = items.map((val) => {
      return {
        item_id: val.id,
        quantity: val.qty,
      }
    })
    let values = {
      issue_items: issue_items,
      status: status,
      remark: remark,
    }

    if (status === 'confirm') {
      values.confirm_date = date
    } else if (status === 'deliver') {
      values.deliever_date = date
    }
    await updateIssue({ issue: values }, id)
    window.location.reload()
  }

  return (
    <WhLayout>
      <Card className="mb-3">
        <CardBody>
          <Info
            info={{
              for: issue?.township?.name,
              status: issue?.status,
              date: issue?.date,
              accept_date: issue?.accept_date,
              delivery_date: issue?.deliever_date,
            }}
          />
        </CardBody>
      </Card>
      <WhItemManager
        removeItem={removeItem}
        changeQty={changeQty}
        items={items}
        status={issue?.status}
      />
      <FormGroup title="Info">
        <Row>
          {issue.status !== 'receive' && (
            <Col>
              <Label>Date</Label>
              <CustomDatePicker onChange={(day) => setDate(day)} value={date} />
            </Col>
          )}
          <Col>
            <Label>Remark</Label>
            <Input onChange={(e) => setRemark(e.target.value)} value={remark} />
          </Col>
        </Row>
      </FormGroup>
      {issue.status !== 'receive' && issue.status !== 'cancel' && (
        <Row className="mt-3">
          <Col sm={{ size: 2, offset: 3 }} xs={{ size: 8, offset: 2 }}>
            <Button
              className="submit-btn mb-3"
              color="warning"
              onClick={() => submit('cancel')}
              disabled={
                issue.status === 'confirm' || issue.status === 'deliver'
              }
            >
              Delete
            </Button>
          </Col>
          <Col sm={{ size: 2, offset: 0 }} xs={{ size: 8, offset: 2 }}>
            <Button
              className="submit-btn mb-3"
              color="success"
              onClick={() => submit('confirm')}
              disabled={
                issue.status === 'confirm' || issue.status === 'deliver'
              }
            >
              Confirm
            </Button>
          </Col>
          <Col sm={{ size: 2, offset: 0 }} xs={{ size: 8, offset: 2 }}>
            <Button
              className="submit-btn mb-3"
              color="success"
              onClick={() => submit('deliver')}
              disabled={issue.status !== 'confirm'}
            >
              Deliver
            </Button>
          </Col>
        </Row>
      )}
    </WhLayout>
  )
}

export default RequestDetail
