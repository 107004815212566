import { createContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import { getDMs, addDM, getDM, editDM, deleteDM } from "api/dm";

const DmContext = createContext({
  data: [],
  eachData: [],
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  deleteData: () => {},
});

const giveObj = (obj) => {
  obj.daily_symptons = JSON.parse(obj.daily_symptons);
  return obj;
};

export function DmContextProvider(props) {
  const [dms, setDMs] = useState([]);
  const [dm, setDM] = useState({});

  const context = {
    data: dms,
    eachData: dm,
    getData: async (visit_id) => {
      setDMs(await getDMs(visit_id));
    },
    getEachData: async (id) => {
      let data = giveObj(await getDM(id));
      setDM(data);
      return data;
    },
    addData: async (values) => {
      let visit = await addDM(values);
      if (visit) {
        setDMs((dms) => [...dms, visit]);
        NotificationManager.success("Success");
      }
    },
    editData: async (values, id) => {
      let data = giveObj(await editDM(values, id));
      if (data) {
        NotificationManager.success("Success");
        let tmpArr = dms.map((value) => {
          if (value.id === id) {
            return data;
          }
          return value;
        });
        setDMs(tmpArr);
      }
    },
    deleteData: async (id) => {
      if (await deleteDM(id)) {
        NotificationManager.success("Deleted");
        setDMs((oldArray) => oldArray.filter((ele) => ele.id != id));
      }
    },
  };
  return (
    <DmContext.Provider value={context}>{props.children}</DmContext.Provider>
  );
}

export default DmContext;
