import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getTownshipStoreIssueOuts,
  getTownshipStoreIssueOut,
  addTownshipStoreIssueOut,
  editTownshipStoreIssueOut,
  deleteTownshipStoreIssueOut,
} from 'api/townshipStoreIssueOut'
const TownshipStoreIssueOutContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})
export function TownshipStoreIssueOutContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      let response = await getTownshipStoreIssueOuts(params)
      setItems(response)
      return response
    },
    getEachData: async (id) => {
      let response = await getTownshipStoreIssueOut(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      if (await addTownshipStoreIssueOut(values)) {
        NotificationManager.success('Success')
      }
    },
    editData: async (values, id) => {
      if (await editTownshipStoreIssueOut(values, id)) {
        NotificationManager.success('Success')
      }
    },
    deleteData: async (id) => {
      if (await deleteTownshipStoreIssueOut(id)) {
        NotificationManager.success('Success')
      }
    },
  }
  return (
    <TownshipStoreIssueOutContext.Provider value={context}>
      {props.children}
    </TownshipStoreIssueOutContext.Provider>
  )
}
export default TownshipStoreIssueOutContext
