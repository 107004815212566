import { useState } from "react";
import { Row, Col, Button, Input, Card } from "reactstrap";
import { Trash } from "react-bootstrap-icons";
import "./household-member.scss";
const HouseholdMember = ({ household, setHouseholds }) => {
  const [value, setValue] = useState("");
  const addNewRow = () => {
    setHouseholds((oldArray) => [...oldArray, value]);
    setValue("");
  };

  return (
    <>
      <Row>
        <Col md={6} xs={10}>
          <Input onChange={(e) => setValue(e.target.value)} value={value} />
        </Col>
        <Col md={6} xs={2}>
          <Button
            color="primary"
            onClick={addNewRow}
            disabled={value.length === 0}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        {household.map((element, index) => (
          <Col md={3} sm={4} xs={12}>
            <Card className="member-list-card">
              <h5>{element}</h5>
              <Trash
                size="20"
                onClick={() => {
                  setHouseholds((oldArray) =>
                    oldArray.filter((z, i) => i !== index)
                  );
                }}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default HouseholdMember;
