import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
export function arrangeRequestBody(data, visit_id) {
  data.daily_monitoring.visit_id = visit_id
  data.daily_monitoring.daily_symptons = JSON.stringify(
    data.daily_monitoring.daily_symptons
  )

  if (data.daily_monitoring.contact == 'no') {
    data.daily_monitoring.daily_symptons = JSON.stringify([])
    data.daily_monitoring.daily_symptons_other = ''
    data.daily_monitoring.spo2 = ''
    data.daily_monitoring.respiratory_reate = ''
    data.daily_monitoring.heart_rate = ''
    data.daily_monitoring.temperature = ''
    data.daily_monitoring.rbs = ''
    data.daily_monitoring.blood_pressure = ''
    data.daily_monitoring.service_type = ''
    data.daily_monitoring.treatment_center = ''
    data.daily_monitoring.treatment_note = ''
  }

  if (typeof data.daily_monitoring.treatment_note == 'object') {
    const rawContentState = convertToRaw(
      data.daily_monitoring.treatment_note.getCurrentContent()
    )
    const markup = draftToHtml(rawContentState)
    data.daily_monitoring.treatment_note = markup
  }

  return data
}
