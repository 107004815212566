import Select from 'react-select'
import React, { useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import { Eraser } from 'react-bootstrap-icons'
import './custom-select.scss'
const CustomSelect = ({
  onChange,
  options,
  value,
  isDisabled = false,
  placeholder = 'Select',
  multi = false,
  name,
  all,
}) => {
  useEffect(() => {
    if (multi && all) {
      if (options[0]?.label !== 'All')
        options.unshift({ label: 'All', value: 'All' })
    }
  })

  const defaultValue = (options, value) => {
    if (multi) {
      if (options) {
        if (value.constructor === Array) {
          return options.filter((opt) => {
            return value?.find((val) => val === opt.value)
          })
        }
      }
    }
    return options ? options.find((option) => option.value === value) : ''
  }
  return (
    <div className="custom-select-container">
      {multi && options?.length === value.length && (
        <Input value="All Options" readonly />
      )}
      {(!multi || (multi && options?.length > value.length)) && (
        <Select
          name={name}
          placeholder={placeholder}
          value={defaultValue(options, value)}
          onChange={(value) => {
            let values = value
            if (multi) {
              if (values.find((val) => val.value === 'All')) {
                values = options.filter((val, index) => {
                  if (index > 0) return val.value
                })
              }
              values = values.map((element) => element.value)
              onChange(values)
            } else {
              onChange(value)
            }
          }}
          options={options}
          isDisabled={isDisabled}
          isMulti={multi}
          className="custom-select-container__select"
        />
      )}
      {multi && options?.length === value.length && (
        <Button
          onClick={() => {
            onChange([])
          }}
        >
          <Eraser />
        </Button>
      )}
    </div>
  )
}

export default CustomSelect
