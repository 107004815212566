import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { addLab, getLabs, getLab, updateLab, deleteLab } from '../api/lab'

const LabContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
})

export function LabContextProvider(props) {
  const [labs, setLabs] = useState([])
  const [lab, setLab] = useState({})
  const history = useHistory()

  const context = {
    data: labs,
    lab: lab,
    getData: async (visit_id) => {
      let response = await getLabs({ visit_id: visit_id })
      setLabs(response)
    },
    getEachData: async (id) => {
      let response = await getLab(id)
      response.body = JSON.parse(response.body)
      setLab(response)
      return response
    },
    addData: async (values) => {
      values.body = JSON.stringify(values.body)
      let result = await addLab({ lab_investigation: values })
      if (result) {
        NotificationManager.success('Success')
        history.push(`/user/lab/${values.visit_id}`)
      }
    },
    updateData: async (id, values) => {
      values.body = JSON.stringify(values.body)
      let result = await updateLab(id, { lab_investigation: values })
      if (result) {
        NotificationManager.success('Success')
        window.location.reload()
      }
    },
    deleteData: async (id) => {
      let response = await deleteLab(id)
      if (response) {
        return true
      }
    },
  }
  return (
    <LabContext.Provider value={context}>{props.children}</LabContext.Provider>
  )
}

export default LabContext
