import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Table } from 'reactstrap'
import { useParams, Link } from 'react-router-dom'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import WhLayout from 'layouts/wh'
import EditForm from 'components/item/editForm'
import ItemContext from 'store/itemContext'
import LoadingContext from 'store/loadingContext'

const EditItem = () => {
  const [initalValues, setInitalValues] = useState({})
  const itemCtx = useContext(ItemContext)
  const { addLoading, actionLoading, addActionLoading } =
    useContext(LoadingContext)
  const { id } = useParams()
  const submitData = async (values) => {
    addActionLoading(true)
    await itemCtx.editData({ item: values }, id)
    addActionLoading(false)
  }
  const deleteData = async () => {
    addActionLoading(true)
    await itemCtx.deleteData(id)
    addActionLoading(false)
  }

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      let data = await itemCtx.getEachData(id)
      setInitalValues(data)
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <WhLayout>
      <h5>Edit Item</h5>
      <Formik
        initialValues={initalValues}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={(values) => submitData(values)}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <EditForm formik={formik} />
            <div class="text-center">
              <Button color="primary" type="submit" disabled={actionLoading}>
                Edit
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                color="danger"
                onClick={deleteData}
                type="button"
                disabled={actionLoading}
              >
                Delete
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-3">
        <Table bordered>
          <thead>
            <th>No</th>
            <th>Batch Number</th>
            <th>Expire Date</th>
            <th>Stock</th>
          </thead>
          <tr>
            <td>1</td>
            <td>Batch 1</td>
            <td>2020-02-02</td>
            <td>
              <Link to="/wh/item-stock/1">
                <ArrowRightCircle size="30" color="green" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>Batch 1</td>
            <td>2020-02-02</td>
            <td>
              <ArrowRightCircle size="40" color="green" />
            </td>
          </tr>
        </Table>
      </div>
    </WhLayout>
  )
}
export default EditItem
