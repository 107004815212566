import { createContext, useState } from "react";
import { getSymptons } from "api/sympton";

const SymptonContext = createContext({
  data: [],
  getData: () => {},
});

export function SymptonContextProvider(props) {
  const [symptons, setSymptons] = useState([]);

  const context = {
    data: symptons,
    getData: async () => {
      if (symptons.length==0)
      setSymptons(await getSymptons());
    },
  };
  return (
    <SymptonContext.Provider value={context}>
      {props.children}
    </SymptonContext.Provider>
  );
}

export default SymptonContext;
