import { createContext, useState } from "react";
import { getRiskFactors } from "api/riskFactor";
const RiskFactorContext = createContext({
  data: [],
  getData: () => {},
});

export function RiskFactorContextProvider(props) {
  const [riskFactors, setRiskFactor] = useState([]);

  const context = {
    data: riskFactors,
    getData: async () => {
      if (riskFactors.length==0)
      setRiskFactor(await getRiskFactors());
    },
  };
  return (
    <RiskFactorContext.Provider value={context}>
      {props.children}
    </RiskFactorContext.Provider>
  );
}

export default RiskFactorContext;
