import Home from '../pages/wh/home'
import HistoricalItemReq from '../pages/wh/historical'
import RequestDetail from '../pages/wh/request'
import ItemRequestReview from '../pages/wh/request/review'
import ItemListPage from '../pages/wh/item'
import NewItem from '../pages/wh/item/new'
import ItemStock from '../pages/wh/item/stock'
import NewItemRequest from '../pages/wh/itemRequest/newItemRequest'
import EditItem from '../pages/wh/item/edit'
import Receives from '../pages/wh/item/receives'
import IssueOuts from '../pages/wh/item/issueOuts'
export const whRoutes = [
  {
    path: '/',
    title: 'Home',
    component: Home,
  },
  {
    path: '/item',
    title: 'ItemListPage',
    component: ItemListPage,
  },
  {
    path: '/new-item',
    title: 'NewItem',
    component: NewItem,
  },
  {
    path: '/edit-item/:id',
    title: 'EditItem',
    component: EditItem,
  },
  {
    path: '/item-stock/:id',
    title: 'ItemStock',
    component: ItemStock,
  },
  {
    path: '/historical',
    title: 'HistoricalItemReq',
    component: HistoricalItemReq,
  },
  {
    path: '/request/:id',
    title: 'RequestDetail',
    component: RequestDetail,
  },
  {
    path: '/request-review/:id',
    title: 'ItemRequestReview',
    component: ItemRequestReview,
  },
  {
    path: '/new-item-request',
    title: 'NewItemRequest',
    component: NewItemRequest,
  },
  {
    path: '/receives/:itemID',
    title: 'Receives',
    component: Receives,
  },
  {
    path: '/issue_outs/:itemID',
    title: 'IssueOuts',
    component: IssueOuts,
  },
]
