import { useContext, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Item from "components/item-request/item";
import WhLayout from "layouts/wh";
import BreadCrumb from "components/elements/breadcrumb";
import ItemRequestContext from "store/itemRequestContext";
import LoadingContext from "store/loadingContext";
import "./index.scss";

const Home = () => {
  //it's just sample array for breadcrumbs
  //u can add component or separate as a breadcrumb file
  const breadcrumbs = [
    {
      path: "",
      name: "Sample 1",
    },
    {
      path: "",
      name: "Sample 2",
    },
  ];
  const itemRequestCtx = useContext(ItemRequestContext);
  const { addLoading } = useContext(LoadingContext);

  useEffect(() => {
    async function fetchApi() {
      addLoading(true);
      await itemRequestCtx.getData();
      addLoading(false);
    }
    fetchApi();
  }, []);

  return (
    <WhLayout>
      {/* <BreadCrumb breadcrumbs={breadcrumbs} /> */}
      <h5>Historical Item Requests</h5>
      <Row className="item-request-container">
        {itemRequestCtx.data.map((element, index) => {
          return (
            <Link to={`/wh/request-review/${element.id}`}>
              <Item element={element} index={index} showStatus={true} />
            </Link>
          );
        })}
      </Row>
    </WhLayout>
  );
};
export default Home;
