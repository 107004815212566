import { useContext, useEffect } from 'react'
import { Card, Col, Row, Button, Input } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import PatientContext from 'store/patientContext.js'
import LoadingContext from 'store/loadingContext.js'
import ExportPatientData from '../../../excel-export/exportPatient'

import './index.scss'
import AdminLayout from '../../../layouts/admin'

const Home = () => {
  const router = useHistory()
  const patientCtx = useContext(PatientContext)
  const { data } = patientCtx
  const { addLoading } = useContext(LoadingContext)
  useEffect(() => {
    async function fetchApi() {
      let query = queryString.parse(router.location.search)
      addLoading(true)
      await patientCtx.getData(query)
      addLoading(false)
    }
    fetchApi()
  }, [router.location.search])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      router.push(`${router.location.pathname}?keyword=${event.target.value}`)
    }
  }

  return (
    <AdminLayout>
      <Row>
        <Col xs="6">
          <h5>Patient List</h5>
        </Col>
        <Col xs={{ size: 6 }} style={{ textAlign: 'right' }}>
          <ExportPatientData datasets={data} />
          <Link to="/admin/new-patient">
            <Button className="mx-1" color="primary" outline>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={{ size: 6, offset: 6 }} xs={{ size: 12 }}>
          <Input placeholder="Search here" onKeyPress={handleKeyPress} />
        </Col>
      </Row>
      <Row className="patient-container">
        <PatientContext.Consumer>
          {({ data }) =>
            data.map((element, index) => (
              <Link to={`/admin/patient/${element.id}`}>
                <Card className="patient-item">
                  <div className="patient-item__info">
                    <span className="patient-item__info__no">{index + 1}.</span>
                    <span className="patient-item__info__title">
                      {element.name} ({element?.unique_code})
                    </span>
                  </div>
                  <div className="patient-item__actions">
                    {element?.visits?.length > 0 && (
                      <span className="bg-success">
                        {element?.visits?.length}
                      </span>
                    )}
                  </div>
                </Card>
              </Link>
            ))
          }
        </PatientContext.Consumer>
      </Row>
    </AdminLayout>
  )
}
export default Home
