import { getData, sendData } from './apiService.js'
export async function getTownshipStoreIssueOuts(params = {}) {
  let response = await getData(`townshipstoredispenceditems`, params)
  return response
}
export async function getTownshipStoreIssueOut(id) {
  let response = await getData(`townshipstoredispenceditems/${id}`)
  return response
}
export async function addTownshipStoreIssueOut(values) {
  return await sendData('townshipstoredispenceditems', values)
}
export async function editTownshipStoreIssueOut(values, id) {
  return await sendData(`townshipstoredispenceditems/${id}`, values, 'PUT')
}

export async function deleteTownshipStoreIssueOut(id) {
  return await sendData(`townshipstoredispenceditems/${id}`, {}, 'DELETE')
}
