import { createContext, useState } from "react";
import { getTownships } from "api/township";
const TownshipContext = createContext({
  data: [],
  getData: () => {},
});

export function TownshipContextProvider(props) {
  const [townships, setTownship] = useState([]);

  const context = {
    data: townships,
    getData: async () => {
      if (townships.length == 0) setTownship(await getTownships());
    },
  };
  return (
    <TownshipContext.Provider value={context}>
      {props.children}
    </TownshipContext.Provider>
  );
}

export default TownshipContext;
