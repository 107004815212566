export const itemRequestData = [
  { id: 1, date: '2020-02-02', items: 3, status: 'pending' },
  { id: 2, date: '2020-03-02', items: 1, status: 'accept' },
  { id: 3, date: '2020-04-02', items: 4, status: 'cancel' },
]

export const itemRequestStatus = [
  { value: 'pending', label: 'Pending' },
  { value: 'accept', label: 'Accept' },
  { value: 'delivery', label: 'Deliver' },
  { value: 'cancel', label: 'Cancel' },
]

export const itemLists = [
  { value: 1, label: 'ABC 22 asdf', code: '0345435', active: 1 },
  { value: 2, label: 'ABC 23 asdf', code: '0345435', active: 1 },
  { value: 3, label: 'Oxygen Oximeter 2GB A', code: '043', active: 0 },
  { value: 4, label: 'ABC 25', code: '0345', active: 0 },
]

export const eachItemReq = {
  from: 'Mahar Aung Myay',
  for: 'Mg Mg (PMDY345)',
  request_date: '2020-02-02',
  status: 'pending',
  items: [
    {
      qty: 3,
      status: 1,
      item: {
        id: 1,
        code: '0001',
        name: 'ABC1',
      },
    },
    {
      qty: 1,
      status: 1,
      item: {
        id: 2,
        code: '0002',
        name: 'ABC2',
      },
    },
  ],
}

export const requestedItem = [
  {
    qty: 3,
    status: 1,
    item: {
      id: 1,
      code: '0001',
      name: 'ABC1',
    },
  },
  {
    qty: 1,
    status: 1,
    item: {
      id: 2,
      code: '0002',
      name: 'ABC2',
    },
  },
]

export const issueOutTypes = [
  { label: 'TMO', value: 'tmo' },
  { label: 'Damage', value: 'damange' },
]
