import React, { useEffect, useContext } from 'react'
import { Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import WhLayout from 'layouts/wh'
import WarehouseIssueOutContext from 'store/warehouseIssueOutContext'
import LoadingContext from 'store/loadingContext'
function IssueOuts() {
  const { getData: getIssueOuts, data: issueOuts } = useContext(
    WarehouseIssueOutContext
  )
  const { addLoading } = useContext(LoadingContext)
  const { itemID } = useParams()
  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await getIssueOuts({ item_id: itemID })
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <WhLayout>
      <Table bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Date</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          <WarehouseIssueOutContext.Consumer>
            {({ data }) =>
              data.map((element, index) => (
                <tr key={`issueOuts-list-${index}`}>
                  <td>{index + 1}</td>
                  <td>{element.issue_damage_date}</td>
                  <td>{element.quantity}</td>
                </tr>
              ))
            }
          </WarehouseIssueOutContext.Consumer>
        </tbody>
      </Table>
    </WhLayout>
  )
}
export default IssueOuts
