import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { PlusCircle } from 'react-bootstrap-icons'
import Parser from 'html-react-parser'
import { convertFromHTML, ContentState, EditorState } from 'draft-js'

import { useParams } from 'react-router'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import AdminLayout from 'layouts/admin'
import AddForm from 'components/dailymonitoring/addForm'
import EditForm from 'components/dailymonitoring/editForm'
import PatientContext from 'store/patientContext'
import SymptonContext from 'store/symptonContext'
import DmContext from 'store/dmContext'
import LoadingContext from 'store/loadingContext'
import VisitContext from 'store/visitContext'
import { getTodayDate, getCurrentTime } from 'lib/dateFormat'
import { arrangeRequestBody } from './utils'
import './index.scss'

const DailyMonitoring = () => {
  let initalValues = {
    daily_monitoring: {
      date: getTodayDate(),
      time: getCurrentTime(),
      contact: '',
      daily_symptons: [],
      daily_symptons_other: '',
      spo2: '',
      respiratory_rate: '',
      heart_rate: '',
      temperature: '',
      temperature_unit: '',
      rbs: '',
      fbs: '',
      hpp: '',
      systolic_blood_pressure: '',
      diastolic_blood_pressure: '',
      treatment_note: '',
      refer_to: '',
      lab_order: '',
      service_type: '',
      treatment_center_name: '',
      physician_id: '',
      home_based_care_id: '',
    },

    lab_investigation: [],
  }
  const [showForm, setShowForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({ daily_monitoring: null })
  const visitCtx = useContext(VisitContext)
  const patientCtx = useContext(PatientContext)
  const symptonCtx = useContext(SymptonContext)
  const dmCtx = useContext(DmContext)
  const { addLoading, addActionLoading } = useContext(LoadingContext)
  const { visit_id } = useParams()
  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }
  const validate = (values) => {
    let validate = true

    if (!values.daily_monitoring.contact) validate = false
    else if (!values.daily_monitoring.refer_to) validate = false
    else if (values.daily_monitoring.rbs.toString().length > 3) {
      validate = false
    } else if (values.daily_monitoring.fbs.toString().length > 3)
      validate = false
    else if (values.daily_monitoring.hpp.toString().length > 3) validate = false
    else if (
      values.daily_monitoring.systolic_blood_pressure.toString().length > 3
    )
      validate = false
    else if (
      values.daily_monitoring.diastolic_blood_pressure.toString().length > 3
    )
      validate = false
    return validate
  }

  const submitData = async (values) => {
    if (validate(values)) {
      addActionLoading(true)
      setShowForm(false)
      await dmCtx.addData(arrangeRequestBody(values, visit_id))
      addActionLoading(false)
    } else {
      NotificationManager.error('Some fields are required')
    }
  }
  const submitEdit = async (values) => {
    if (validate(values)) {
      addActionLoading(true)
      setIsEdit(false)
      await dmCtx.editData(
        arrangeRequestBody(values, visit_id),
        editData.daily_monitoring.id
      )
      addActionLoading(false)
    } else {
      NotificationManager.error('Some fields are required')
    }
  }
  const deleteData = async () => {
    addActionLoading(true)
    await dmCtx.deleteData(editData.daily_monitoring.id)
    setIsEdit(false)
    addActionLoading(false)
  }

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await symptonCtx.getData()
      await dmCtx.getData(visit_id)
      let visit = await visitCtx.getEachData(visit_id)
      await patientCtx.getEachData(visit.patient_id)

      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <AdminLayout>
      <PatientContext.Consumer>
        {({ eachData }) => (
          <h5>
            Daily Monitoring for {eachData?.name} ({eachData?.unique_code})
          </h5>
        )}
      </PatientContext.Consumer>
      {showForm && (
        <Formik
          initialValues={initalValues}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          onSubmit={(values) => submitData(values)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <AddForm formik={formik} cancel={() => setShowForm(false)} />
            </Form>
          )}
        </Formik>
      )}

      {isEdit && editData.daily_monitoring != null && (
        <Formik
          initialValues={editData}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          onSubmit={(values) => submitEdit(values)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <EditForm
                formik={formik}
                cancel={() => setIsEdit(false)}
                deleteData={deleteData}
              />
            </Form>
          )}
        </Formik>
      )}

      {!showForm && (
        <Row className="dm-list">
          <Col md={6} sm={12} xs={12}>
            <Card
              className="dm-list__card new-btn"
              onClick={() => setShowForm(true)}
            >
              <span>New Daily Monitoring</span>
              <PlusCircle size="30" />
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <DmContext.Consumer>
          {({ data }) =>
            data.map((element, index) => (
              <Col md={3} sm={6} xs={12}>
                <Card
                  className="dm-list__card"
                  onClick={async () => {
                    setEditData({})
                    let dm = await dmCtx.getEachData(element.id)

                    dm.treatment_note = getDraft(dm.treatment_note)

                    setEditData({
                      daily_monitoring: dm,
                    })
                    setIsEdit(true)
                  }}
                >
                  <div>
                    <p>
                      {element.date} ({element.time})
                    </p>
                    <p>Fever Day {element.fever_day}</p>
                    <p>Filled by {element.filled_by}</p>
                    <p>
                      <u>Treatment Note</u>
                    </p>
                    <p>
                      {element.treatment_note
                        ? Parser(element.treatment_note)
                        : ''}
                    </p>
                  </div>
                  {element.contact === 'yes' && (
                    <span className="dm-list__card__status">Y</span>
                  )}
                  {element.contact === 'no' && (
                    <span className="dm-list__card__status bg-danger">N</span>
                  )}
                </Card>
              </Col>
            ))
          }
        </DmContext.Consumer>
      </Row>
    </AdminLayout>
  )
}
export default DailyMonitoring
