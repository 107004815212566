import { getData, sendData } from "./apiService.js";
export async function getItemRequests(params = {}) {
  let response = await getData(`item_requests`, params);
  return response;
}
export async function getItemRequest(id) {
  let response = await getData(`item_requests/${id}`);
  return response;
}
export async function addItemRequest(values) {
  return await sendData("item_requests", values);
}
export async function editItemRequest(values, id) {
  return await sendData(`item_requests/${id}`, values, "PUT");
}

export async function deleteItemRequest(id) {
  return await sendData(`item_requests/${id}`, {}, "DELETE");
}
