import React, { useState, useContext } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import UserLayout from 'layouts/user'
import Item from 'components/dispense/item'
import ItemDispensingContext from 'store/itemDispensingContext'
const Dispense = () => {
  const [loading, setLoading] = useState(false)
  const { getData: getDispensings, data: dispensings } = useContext(
    ItemDispensingContext
  )

  const { visitID } = useParams()

  useState(() => {
    async function fetchApi() {
      setLoading(true)
      await getDispensings({ visit_id: visitID })
      setLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      {!loading && (
        <>
          <Row className="mb-3">
            <Col xs="6">
              <h5>Depensing List</h5>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Link to={`/user/new-dispense/${visitID}`}>
                <Button color="primary">Add New</Button>
              </Link>
            </Col>
          </Row>
          {dispensings.map((element, index) => (
            <Link
              to={`/user/review-dispense/${element.id}`}
              key={`dispensing-item-${index}`}
            >
              <Item index={index} element={element} />
            </Link>
          ))}
        </>
      )}
    </UserLayout>
  )
}
export default Dispense
