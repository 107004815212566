import { createContext, useState } from 'react'
import { getHomeBasedCares } from 'api/homeBasedCare'
const HomeBasedCareContext = createContext({
  data: [],
  getData: () => {},
})
export function HomeBasedCareContextProvider(props) {
  const [homeBasedCare, setHomeBasedCare] = useState([])

  const context = {
    data: homeBasedCare,
    getData: async () => {
      if (homeBasedCare.length == 0) setHomeBasedCare(await getHomeBasedCares())
    },
  }
  return (
    <HomeBasedCareContext.Provider value={context}>
      {props.children}
    </HomeBasedCareContext.Provider>
  )
}
export default HomeBasedCareContext
