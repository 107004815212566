import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCache } from "lib/cache";

const WhProtectedRoute = ({ component: Component, ...rest }) => {
  const token = getCache("token");
  const userData = JSON.parse(getCache("userData"));
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token && userData.role == "warehouse") {
          return <Component {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
      }}
    />
  );
};

export default WhProtectedRoute;
