import { useContext, useEffect, useState } from 'react'
import Stepper from 'react-stepper-horizontal'
import { useHistory, useParams } from 'react-router'
import { Card, CardBody, CardTitle } from 'reactstrap'
import UserLayout from 'layouts/user'
import RequestForm from 'components/dispense/requestForm'
import ReviewPage from 'components/dispense/reivewPage'
import ItemContext from 'store/itemContext'
import LoadingContext from 'store/loadingContext'
import PatientContext from 'store/patientContext.js'
import TownshipStoreContext from 'store/townshipStoreContext'
import ItemDispensingContext from 'store/itemDispensingContext'
const NewDispense = () => {
  const [selectItems, setSelectedItems] = useState([])
  const [step, setStep] = useState(0)
  const [date, setDate] = useState('')
  const { addLoading, addActionLoading } = useContext(LoadingContext)
  const { data: townshipStores, getData: getTownshipStores } =
    useContext(TownshipStoreContext)
  const patientCtx = useContext(PatientContext)
  const { addData: addDispensing } = useContext(ItemDispensingContext)
  const history = useHistory()
  const { visitID } = useParams()
  const choose = (selectedItem) => {
    if (!selectItems.find((val) => val.id === selectedItem.id))
      setSelectedItems((oldArray) => [...oldArray, selectedItem])
  }
  const removeItem = (id) => {
    setSelectedItems(selectItems.filter((item) => item.id !== id))
  }
  const changeQty = (index, value) => {
    setSelectedItems(
      selectItems.map((sel, i) => {
        if (i === index) sel.qty = value
        return sel
      })
    )
  }
  const onSave = async () => {
    addActionLoading(true)
    const dispence_items = selectItems.map((element) => ({
      township_store_id: element.id.value,
      quantity: element.qty,
      dispence_date: date,
    }))

    await addDispensing({
      dispence: {
        dispence_date: date,
        dispence_items: dispence_items,
        status: 'dispence',
        visit_id: visitID,
      },
    })
    addActionLoading(false)
    history.replace('/user/dispense/' + visitID)
  }

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await getTownshipStores()
      await patientCtx.getData()
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <h5>New Dispense</h5>
      <Card>
        <CardTitle>
          <Stepper
            steps={[{ title: 'Prepare' }, { title: 'Review' }]}
            activeStep={step}
          />
        </CardTitle>
        <ItemContext.Consumer>
          {({ data }) => (
            <CardBody>
              {step === 0 && (
                <RequestForm
                  selectItems={selectItems}
                  choose={choose}
                  removeItem={removeItem}
                  changeQty={changeQty}
                  nextTo={() => setStep(1)}
                  items={townshipStores}
                  data={date}
                  setDate={setDate}
                />
              )}
              {step === 1 && (
                <ReviewPage
                  selectItems={selectItems}
                  backTo={() => setStep(0)}
                  items={data}
                  date={date}
                  onSave={onSave}
                />
              )}
            </CardBody>
          )}
        </ItemContext.Consumer>
      </Card>
    </UserLayout>
  )
}
export default NewDispense
