import { useContext } from 'react'
import { Row, Col, Button } from 'reactstrap'
import DMInputForm from './inputForm'
import LabOrderForm from './../lab/labOrderForm'
import LoadingContext from 'store/loadingContext'

const AddForm = ({ formik, cancel }) => {
  const { actionLoading } = useContext(LoadingContext)

  return (
    <>
      <DMInputForm title={`New  Daily Monitoring`} formik={formik} />

      <Row className="mt-3">
        <Col sm={{ size: 2, offset: 8 }} xs={{ size: 6, offset: 0 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            type="submit"
            disabled={actionLoading}
          >
            Save
          </Button>
        </Col>
        <Col sm={{ size: 2, offset: 0 }} xs={{ size: 6, offset: 0 }}>
          <Button
            className="submit-btn mb-3"
            color="primary"
            onClick={cancel}
            disabled={actionLoading}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  )
}
export default AddForm
