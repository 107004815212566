import dayjs from 'dayjs'
import { css } from '@emotion/react'
import ReactExport from 'react-export-excel'
import { ButtonGroup, Button } from 'reactstrap'
import { typeOptions } from '../mock/patient-form-options'

const ExportPatientData = ({ datasets }) => {
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

  // const changeDateFormat = (date) => {
  //   if (date) return dayjs(date).format('DD/MM/YYYY')
  //   return date
  // }

  const changeArrayFormat = (arr) => {
    const newArr = JSON.parse(arr)
    if (Array.isArray(newArr)) return newArr.join()
    return arr
  }

  const exportedData = []
  datasets &&
    datasets.map((val) => {
      exportedData.push({
        name: val?.name,
        code: val?.code,
        type: typeOptions.find((a) => a.value == val?.type)?.label,
        age: val?.age,
        sex: val?.sex,
        education: val?.education,
        marital_status: val?.marital_status,
        address: val?.address,
        phone_number: val?.phone_number,
        occupation: val?.occupation,
        township: val?.township.name,
        name_ofhousehold: changeArrayFormat(val?.name_ofhousehold),
        risk_factors: changeArrayFormat(val?.risk_factors),
        risk_factors_other: changeArrayFormat(val?.risk_factors_other),
        vaccinated: val?.vaccinated,
        vaccine_doses: val?.vaccine_doses,
        vaccine_name: val?.vaccine_name,
        last_vaccine_date: val?.last_vaccine_date,
        register_date: val?.register_date,
        contact_with_19: val?.contact_with_19,
        contact_with_19_place: val?.contact_with_19_place,
        international_travel: val?.international_travel,
        local_travel: val?.local_travel,
        covid_test: val?.visits[0]?.covid_test,
        covid_date: val?.visits[0]?.covid_date,
        covid_test_type: val?.visits[0]?.covid_test_type,
        covid_result: val?.visits[0]?.covid_result,
        covid_township_self: val?.visits[0]?.covid_township_self,
        outcome: val?.visits[0]?.outcome,
        outcome_date: val?.visits[0]?.outcome_date,
        outcome_remark: val?.visits[0]?.outcome_remark,
      })
    })

  return (
    <ExcelFile
      filename="Covid 19 Patient List"
      element={
        <ButtonGroup css={styles.buttonGroup}>
          <a>
            <Button className="download-button" color="success" outline>
              <i className="fas fa-file-excel"></i>
              Export Patient List
            </Button>
          </a>
        </ButtonGroup>
      }
    >
      <ExcelSheet data={exportedData} name="Inventory List">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Code" value="code" />
        <ExcelColumn label="Type" value="type" />
        <ExcelColumn label="Age" value="age" />
        <ExcelColumn label="Sex" value="sex" />
        <ExcelColumn label="Township" value="township" />
        <ExcelColumn label="Education" value="education" />
        <ExcelColumn label="Marital Status" value="marital_status" />
        <ExcelColumn label="Address" value="address" />
        <ExcelColumn label="Phone Number" value="phone_number" />
        <ExcelColumn label="Occupation" value="occupation" />
        <ExcelColumn label="No of Household" value="name_ofhousehold" />
        <ExcelColumn label="Register date" value="register_date" />
        <ExcelColumn label="Risk factors" value="risk_factors" />
        <ExcelColumn label="Risk factors other" value="risk_factors_other" />
        <ExcelColumn label="Vaccinated" value="vaccinated" />
        <ExcelColumn label="Vaccine: Doses" value="vaccine_doses" />
        <ExcelColumn label="Vaccine: Name" value="vaccine_name" />
        <ExcelColumn
          label="Date of last COVID-19 vaccine dose"
          value="last_vaccine_date"
        />
        <ExcelColumn
          label="History of contact with known COVID-19 case"
          value="contact_with_19"
        />
        <ExcelColumn
          label="Place of contact with Covid19 case"
          value="contact_with_19_place"
        />
        <ExcelColumn
          label="International travel"
          value="international_travel"
        />
        <ExcelColumn label="Local travel" value="Local travel" />
        <ExcelColumn label="Covid Test" value="covid_test" />
        <ExcelColumn label="Covid Test Type" value="covid_test_type" />
        <ExcelColumn label="Covid Date" value="covid_date" />
        <ExcelColumn label="Covid Result" value="covid_result" />
        <ExcelColumn label="Covid Test Type" value="covid_test_type" />
        <ExcelColumn label="Covid Township Self" value="covid_township_self" />
        <ExcelColumn label="Outcome" value="outcome" />
        <ExcelColumn label="Outcome Date" value="outcome_date" />
        <ExcelColumn label="Outcome Remark" value="outcome_remark" />
      </ExcelSheet>
    </ExcelFile>
  )
}
export default ExportPatientData
const styles = {
  buttonGroup: css`
    display: flex;
    flex-wrap: wrap;
    .download-button {
      width: 150px;
      i {
        font-size: 30px;
      }
      span {
        font-size: 12px;
        display: block;
      }
    }
  `,
}
