import Home from './../pages/user/home'
import NewPatient from './../pages/user/patient/new'
import EditPatient from './../pages/user/patient/edit'
import PatientDetail from './../pages/user/patient/detail'
import NewVisit from './../pages/user/visit/new'
import VisitDetail from './../pages/user/visit/detail'
import DailyMonitoring from './../pages/user/dailymonitoring'
import Lab from './../pages/user/lab'
import LabDetail from './../pages/user/lab/detail'
import NewLab from './../pages/user/lab/new'
import ItemRequest from './../pages/user/itemrequest'
import NewItemRequest from './../pages/user/itemrequest/new'
import EditItemRequest from './../pages/user/itemrequest/edit'
import ItemRequestReview from '../pages/user/itemrequest/review'
import Item from '../pages/user/item'
import EditItem from '../pages/user/item/edit'
import Dispense from '../pages/user/dispense'
import NewDispense from '../pages/user/dispense/new'
import ReviewDispense from '../pages/user/dispense/review'

export const userRoutes = [
  {
    path: '/',
    title: 'Home',
    component: Home,
  },
  {
    path: '/new-patient',
    title: 'NewPatient',
    component: NewPatient,
  },

  {
    path: '/patient/:id',
    title: 'PatientDetail',
    component: PatientDetail,
  },
  {
    path: '/edit-patient/:id',
    title: 'EditPatient',
    component: EditPatient,
  },
  {
    path: '/new-visit/:patient_id',
    title: 'NewVisit',
    component: NewVisit,
  },
  {
    path: '/visit/:id',
    title: 'VisitDetail',
    component: VisitDetail,
  },
  {
    path: '/daily-monitoring/:visit_id',
    title: 'DailyMonitoring',
    component: DailyMonitoring,
  },
  {
    path: '/lab/:visit_id',
    title: 'Lab',
    component: Lab,
  },
  {
    path: '/lab-detail/:id',
    title: 'LabDetail',
    component: LabDetail,
  },
  {
    path: '/new-lab/:visit_id',
    title: 'NewLab',
    component: NewLab,
  },
  {
    path: '/item-request',
    title: 'ItemRequest',
    component: ItemRequest,
  },
  {
    path: '/new-item-request',
    title: 'NewItemRequest',
    component: NewItemRequest,
  },
  {
    path: '/edit-item-request/:id',
    title: 'EditItemRequest',
    component: EditItemRequest,
  },
  {
    path: '/item-request-review/:id',
    title: 'ItemRequestReview',
    component: ItemRequestReview,
  },
  {
    path: '/item',
    title: 'Item',
    component: Item,
  },
  {
    path: '/edit-item/:id',
    title: 'EditItem',
    component: EditItem,
  },
  {
    path: '/dispense/:visitID',
    title: 'Dispense',
    component: Dispense,
  },
  {
    path: '/new-dispense/:visitID',
    title: 'NewDispense',
    component: NewDispense,
  },
  {
    path: '/review-dispense/:id',
    title: 'ReviewDispense',
    component: ReviewDispense,
  },
]
