import { useState, useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Button } from 'reactstrap'
import { useParams } from 'react-router'
import { NotificationManager } from 'react-notifications'
import UserLayout from 'layouts/user'
import InfoForm from 'components/patient/infoForm'
import PatientContext from 'store/patientContext'
import LoadingContext from 'store/loadingContext'
import './patient.scss'

const EditPatient = () => {
  const [initalValues, setInitValue] = useState({
    register_date: '',
    name: '',
    age: '',
    sex: '',
    education: '',
    address: '',
    phone_number: '',
    martial_status: '',
    occupation: '',
    risk_factors: [],
    risk_factor_other: '',
    vaccinated: '',
    vaccine_name: '',
    vaccine_doses: '',
    last_vaccine_date: '',
    contact_with_19: '',
    contact_with_19_place: '',
    local_travel: '',
    international_travel: '',
    remark: '',
  })
  const [household, setHouseholds] = useState([])
  const { getEachData, editData } = useContext(PatientContext)
  const { addLoading, loading, actionLoading, addActionLoading } =
    useContext(LoadingContext)
  const { id } = useParams()

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      let response = await getEachData(id)
      setInitValue(response)
      setHouseholds(response.name_ofhousehold)
      addLoading(false)
    }
    fetchApi()
  }, [])

  const validate = (values) => {
    let validate = true
    if (!values.type) validate = false
    if (!values.code) validate = false
    if (!values.register_date) validate = false
    if (!values.name) validate = false
    if (!values.age) validate = false
    if (!values.sex) validate = false
    if (!values.education) validate = false
    if (!values.address) validate = false
    if (!values.phone_number) validate = false
    if (!values.marital_status) validate = false
    if (!values.occupation) validate = false
    if (!values.risk_factors.length === 0) validate = false

    return validate
  }

  const submitData = async (values) => {
    values.name_ofhousehold = JSON.stringify(household)
    values.risk_factors = JSON.stringify(values.risk_factors)
    if (validate(values)) {
      addActionLoading(true)
      await editData({ patient: values }, id)
      addActionLoading(false)
    } else {
      NotificationManager.error('Some fields are required')
    }
  }
  return (
    <UserLayout>
      <h5>Edit Patient</h5>
      {!loading && (
        <Formik
          initialValues={initalValues}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          onSubmit={(values) => submitData(values)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <InfoForm
                formik={formik}
                household={household}
                setHouseholds={setHouseholds}
                isEdit={true}
              />
              <Row className="mt-3">
                <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
                  <Button
                    type="submit"
                    className="submit-btn mb-3"
                    color="primary"
                    disabled={actionLoading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </UserLayout>
  )
}
export default EditPatient
