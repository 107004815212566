import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Stepper from 'react-stepper-horizontal'
import { Card, CardBody, CardTitle } from 'reactstrap'
import UserLayout from 'layouts/user'
import RequestForm from 'components/item-request/wh/requestForm'
import ReviewPage from 'components/item-request/reivewPage'
import ItemContext from 'store/itemContext'
import LoadingContext from 'store/loadingContext'
import TownshipContext from 'store/townshipContext'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import { getTodayDate } from 'lib/dateFormat'
const NewItemRequest = () => {
  const [selectItems, setSelectedItems] = useState([])
  const [step, setStep] = useState(0)
  const [info, setInfo] = useState({
    township_id: '',
    status: 'pending',
    date: getTodayDate(),
  })
  let history = useHistory()
  const { addData: addIssue } = useContext(WarehouseIssueContext)
  const { getData: getTownships, data: townships } = useContext(TownshipContext)
  const itemCtx = useContext(ItemContext)
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)

  const choose = (selectedItem) => {
    if (!selectItems.find((val) => val.id === selectedItem.id))
      setSelectedItems((oldArray) => [...oldArray, selectedItem])
  }
  const removeItem = (id) => {
    setSelectedItems(selectItems.filter((item) => item.id !== id))
  }
  const changeQty = (index, value) => {
    setSelectedItems(
      selectItems.map((sel, i) => {
        if (i === index) sel.qty = value
        return sel
      })
    )
  }
  const onSave = async () => {
    addActionLoading(true)
    let issue_items = selectItems.map((val) => {
      return {
        item_id: val.id,
        quantity: val.qty,
      }
    })
    let values = {
      issue_items: issue_items,
      township_id: info.township_id,
      date: info.date,
    }
    await addIssue({ issue: values })
    addActionLoading(false)
    history.push('/wh')
  }

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await getTownships()
      await itemCtx.getData()
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <h5>New Item Submission</h5>
      <Card>
        <CardTitle>
          <Stepper
            steps={[{ title: 'Prepare' }, { title: 'Review' }]}
            activeStep={step}
          />
        </CardTitle>
        <ItemContext.Consumer>
          {({ data }) => (
            <CardBody>
              {step === 0 && (
                <RequestForm
                  selectItems={selectItems}
                  choose={choose}
                  removeItem={removeItem}
                  changeQty={changeQty}
                  nextTo={() => setStep(1)}
                  items={data}
                  info={info}
                  setInfo={setInfo}
                />
              )}
              {step === 1 && (
                <ReviewPage
                  selectItems={selectItems}
                  backTo={() => setStep(0)}
                  items={data}
                  info={{
                    for: townships.find(
                      (element) => element.id === info.township_id
                    ).name,
                    status: 'Pending',
                    created_by: 'Warehouse',
                    date: info.date,
                  }}
                  onSave={onSave}
                />
              )}
            </CardBody>
          )}
        </ItemContext.Consumer>
      </Card>
    </UserLayout>
  )
}
export default NewItemRequest
