import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { Card } from 'reactstrap'
import UserLayout from 'layouts/user.js'
import TownshipStoreContext from 'store/townshipStoreContext'
const Item = () => {
  const { getData: getTownshipStores, data: townshipStores } =
    useContext(TownshipStoreContext)
  useEffect(() => {
    async function loadData() {
      await getTownshipStores()
    }
    loadData()
  }, [])
  return (
    <UserLayout>
      <h5>Item Stock</h5>
      {townshipStores.map((element, index) => (
        <Link to={`/user/edit-item/${element.id}`}>
          <Card className="item-list-item">
            <div className="item-list-item__info">
              <span className="item-list-item__info__no">{index + 1}.</span>
              <span className="item-list-item__info__title">
                {element.item.item} {element.item.au}
              </span>
            </div>
            <div className="item-list-item__actions">
              <span className="bg-danger">{element.closing_balance}</span>
            </div>
          </Card>
        </Link>
      ))}
    </UserLayout>
  )
}
export default Item
