import { createContext, useState } from 'react'
import { getPatients, addPatient, getPatient, editPatient } from 'api/patient'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { deletePatient } from '../api/patient'

const PatientContext = createContext({
  data: [],
  eachData: [],
  getData: () => {},
  getEachData: () => {},
})

const givePatientObj = (patient) => {
  patient.name_ofhousehold = JSON.parse(patient.name_ofhousehold)
  patient.risk_factors = JSON.parse(patient.risk_factors)
  return patient
}

export function PatientContextProvider(props) {
  const [patients, setPatients] = useState([])
  const [patient, setPatient] = useState({})
  const history = useHistory()

  const context = {
    data: patients,

    eachData: patient,
    getData: async (params) => {
      setPatients(await getPatients(params))
    },
    getEachData: async (id) => {
      let data = givePatientObj(await getPatient(id))
      setPatient(data)
      return data
    },
    addData: async (values) => {
      let patient = await addPatient(values)
      if (patient) {
        NotificationManager.success('Success')
        history.push(`/user/patient/${patient.id}`)
      }
    },
    deleteData: async (id) => {
      await deletePatient(id)
      history.push(`/admin`)
    },
    editData: async (values, id) => {
      NotificationManager.success('Success')
      await editPatient(values, id)
    },
  }
  return (
    <PatientContext.Provider value={context}>
      {props.children}
    </PatientContext.Provider>
  )
}

export default PatientContext
