import { createContext, useState } from 'react'
import { getPhysicians } from 'api/physician'
const PhysicianContext = createContext({
  data: [],
  getData: () => {},
})
export function PhysicianContextProvider(props) {
  const [physicans, setPhysician] = useState([])

  const context = {
    data: physicans,
    getData: async () => {
      if (physicans.length == 0) setPhysician(await getPhysicians())
    },
  }
  return (
    <PhysicianContext.Provider value={context}>
      {props.children}
    </PhysicianContext.Provider>
  )
}
export default PhysicianContext
