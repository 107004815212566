import { Row, Col, Label, Input } from 'reactstrap'
import CustomDatePicker from '../elements/customDatePicker'
import FormGroup from '../layouts/formGroup'
const AddForm = ({ formik }) => {
  return (
    <FormGroup title="Item Information">
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Item Name</Label>
          <Input
            name="item"
            onChange={formik.handleChange}
            value={formik.values.item}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>AU</Label>
          <Input
            name="au"
            onChange={formik.handleChange}
            value={formik.values.au}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Expire Date</Label>
          <CustomDatePicker
            name="expire_date"
            value={formik.values.expire_date}
            onChange={(day) => formik.setFieldValue('expire_date', day)}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Batch Number</Label>
          <Input
            name="batch_number"
            onChange={formik.handleChange}
            value={formik.values.batch_number}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Opening Balance</Label>
          <Input
            name="quantity"
            onChange={formik.handleChange}
            value={formik.values.quantity}
            type="number"
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Date</Label>
          <CustomDatePicker
            name="date"
            value={formik.values.date}
            onChange={(day) => formik.setFieldValue('date', day)}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}
export default AddForm
