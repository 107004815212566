import "./customSelect";
import "./custom-time-picker.scss";
import CustomSelect from "./customSelect";
import { useState } from "react";
const CustomTimePicker = ({ onValueChange, defaultValue }) => {
  let hours = [];
  for (let i = 1; i <= 12; i++) {
    let val = i >= 10 ? i : `0${i}`;
    hours.push({ label: val, value: val.toString() });
  }
  let mins = [];
  for (let i = 0; i < 60; i++) {
    let val = i >= 10 ? i : `0${i}`;
    mins.push({ label: val, value: val.toString() });
  }

  const timeArray = defaultValue.split(":");

  const [hour, setHour] = useState(timeArray[0]);
  const [minute, setMinute] = useState(timeArray[1]);
  const [diff, setDiff] = useState(timeArray[2]);

  const callOnChange = (type, value) => {
    var result = "";
    switch (type) {
      case "H":
        result = `${value}:${minute}:${diff}`;
        break;
      case "M":
        result = `${hour}:${value}:${diff}`;
        break;
      case "D":
        result = `${hour}:${minute}:${value}`;
        break;
      default:
    }
    onValueChange(result);
  };

  return (
    <div className="time-picker-container">
      <CustomSelect
        placeholder="H"
        value={hour}
        options={hours}
        onChange={(val) => {
          setHour(val.value);
          callOnChange("H", val.value);
        }}
      />
      <CustomSelect
        placeholder="M"
        value={minute}
        options={mins}
        onChange={(val) => {
          setMinute(val.value);
          callOnChange("M", val.value);
        }}
      />
      <CustomSelect
        placeholder="AM/PM"
        value={diff}
        options={[
          { value: "AM", label: "AM" },
          { value: "PM", label: "PM" },
        ]}
        onChange={(val) => {
          setDiff(val.value);
          callOnChange("D", val.value);
        }}
      />
    </div>
  );
};
export default CustomTimePicker;
