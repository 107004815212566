import { Card, CardTitle, CardBody } from "reactstrap";
import "./form-group.scss";
const FormGroup = ({ title, children }) => {
  return (
    <Card className="form-card">
      <CardTitle className="form-card__title">
        <h5>{title}</h5>
      </CardTitle>
      <CardBody className="form-card__body">{children}</CardBody>
    </Card>
  );
};
export default FormGroup;
