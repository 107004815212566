import { getData, sendData } from './apiService.js'
export async function getWarehouseIssueOuts(params = {}) {
  let response = await getData(`warehouseissueditems`, params)
  return response
}
export async function getWarehouseIssueOut(id) {
  let response = await getData(`warehouseissueditems/${id}`)
  return response
}
export async function addWarehouseIssueOut(values) {
  return await sendData('warehouseissueditems', values)
}
export async function editWarehouseIssueOut(values, id) {
  return await sendData(`warehouseissueditems/${id}`, values, 'PUT')
}

export async function deleteWarehouseIssueOut(id) {
  return await sendData(`warehouseissueditems/${id}`, {}, 'DELETE')
}
