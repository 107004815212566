import React from 'react'
import { NavItem, NavLink, Nav } from 'reactstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { PersonCircle } from 'react-bootstrap-icons'
import './sidebar.scss'

const SideBar = ({ isOpen }) => (
  <div className={classNames('sidebar', { hidden: isOpen })}>
    <div>
      <Nav vertical className="list-unstyled pb-3">
        <NavItem>
          <NavLink tag={Link} to={'/user'}>
            <PersonCircle className="icon" /> Patients
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={'/user/item-request'}>
            <PersonCircle className="icon" /> Item Request
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={'/user/item'}>
            <PersonCircle className="icon" /> Item Stock
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  </div>
)

export default SideBar
