import { Formik } from 'formik'
import { Row, Col, Button, Form } from 'reactstrap'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import UserLayout from 'layouts/user'
import InfoForm from 'components/visit/infoForm'
import CovidForm from 'components/visit/covidForm'
import DMInputForm from 'components/dailymonitoring/inputForm'
import LabOrderForm from 'components/lab/labOrderForm'
import SymptonContext from 'store/symptonContext'
import LoadingContext from 'store/loadingContext'
import VisitContext from 'store/visitContext'
import PatientContext from 'store/patientContext'
import { getTodayDate } from 'lib/dateFormat'
import { getCurrentTime } from '../../../lib/dateFormat'

const NewVisit = () => {
  let initalValues = {
    visit: {
      date: getTodayDate(),
      onset_symptons: [],
      onset_symptons_date: '',
      onset_symptons_other: '',

      covid_test: '',
      covid_date: '',
      covid_result: '',
      covid_test_type: '',
      covid_township_self: '',
      covid_township_option: '',
    },
    daily_monitoring: {
      date: getTodayDate(),
      time: getCurrentTime(),
      contact: '',
      daily_symptons: [],
      daily_symptons_other: '',
      spo2: '',
      respiratory_rate: '',
      heart_rate: '',
      temperature: '',
      temperature_unit: '',
      rbs: '',
      fbs: '',
      hpp: '',
      systolic_blood_pressure: '',
      diastolic_blood_pressure: '',
      treatment_note: '',
      refer_to: '',
      lab_order: '',
      service_type: '',
      physician_id: '',
      home_based_care_id: '',
      treatment_center_name: '',
    },

    lab_investigation: [],
  }

  const symptonCtx = useContext(SymptonContext)
  const patientCtx = useContext(PatientContext)
  const { addLoading, loading, actionLoading, addActionLoading } =
    useContext(LoadingContext)
  const { addData } = useContext(VisitContext)
  const { patient_id } = useParams()

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await symptonCtx.getData()
      await patientCtx.getEachData(patient_id)
      addLoading(false)
    }
    fetchApi()
  }, [])

  const validate = (values) => {
    let validate = true
    if (!values.visit.onset_symptons.length > 0) validate = false
    else if (!values.visit.onset_symptons_date) validate = false
    else if (!values.visit.covid_test) validate = false
    else if (!values.daily_monitoring.contact) validate = false
    else if (!values.daily_monitoring.refer_to) validate = false
    else if (!values.daily_monitoring.refer_to) validate = false
    else if (values.daily_monitoring.rbs.toString().length > 3) validate = false
    else if (values.daily_monitoring.fbs.toString().length > 3) validate = false
    else if (values.daily_monitoring.hpp.toString().length > 3) validate = false
    else if (
      values.daily_monitoring.systolic_blood_pressure.toString().length > 3
    )
      validate = false
    else if (
      values.daily_monitoring.diastolic_blood_pressure.toString().length > 3
    )
      validate = false
    return validate
  }

  const submitData = async (values) => {
    values.daily_monitoring.daily_symptons = JSON.stringify(
      values.daily_monitoring.daily_symptons
    )
    values.visit.onset_symptons = JSON.stringify(values.visit.onset_symptons)
    values.daily_monitoring.time = '1:00:PM'
    values.visit.patient_id = patient_id
    if (validate(values)) {
      addActionLoading(true)
      await addData(values)
      addActionLoading(false)
    } else {
      NotificationManager.error('Some fields are required or invalid')
    }
  }

  return (
    <UserLayout>
      <h5>
        New Visit of {patientCtx.eachData.name} (
        {patientCtx.eachData.unique_code})
      </h5>
      {!loading && (
        <>
          <Formik
            initialValues={initalValues}
            validate={(values) => {
              const errors = {}
              return errors
            }}
            onSubmit={(values) => submitData(values)}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <InfoForm formik={formik} />
                <CovidForm formik={formik} />
                <DMInputForm title="Daily Monitoring (Day 0)" formik={formik} />

                <Row className="mt-3">
                  <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
                    <Button
                      className="submit-btn mb-3"
                      color="primary"
                      disabled={actionLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
      )}
    </UserLayout>
  )
}
export default NewVisit
