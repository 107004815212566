import { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { getVisits, addVisit, getVisit, editVisit } from 'api/visit'
const VisitContext = createContext({
  data: [],
  eachData: [],
  getData: () => {},
  getEachData: () => {},
})

const giveVisitObject = (obj) => {
  //   patient.name_ofhousehold = JSON.parse(patient.name_ofhousehold);
  obj.onset_symptons = JSON.parse(obj.onset_symptons)
  return obj
}

export function VisitContextProvider(props) {
  const [visits, setVisits] = useState([])
  const [visit, setVisit] = useState({})
  const history = useHistory()

  const context = {
    data: visits,
    eachData: visit,
    getData: async () => {
      setVisits(await getVisits())
    },
    getEachData: async (id) => {
      let data = giveVisitObject(await getVisit(id))
      setVisit(data)
      return data
    },
    addData: async (values) => {
      let visit = await addVisit(values)
      if (visit) {
        NotificationManager.success('Success')
        history.push(`/user/patient/${visit.patient_id}`)
      }
    },
    editData: async (values, id) => {
      NotificationManager.success('Success')
      await editVisit(values, id)
    },
  }
  return (
    <VisitContext.Provider value={context}>
      {props.children}
    </VisitContext.Provider>
  )
}

export default VisitContext
