import { getData, sendData } from './apiService.js'
export async function getLabs(params = {}) {
  let response = await getData('lab_investigations', params)
  return response
}
export async function getLab(id) {
  let response = await getData(`lab_investigations/${id}`)
  return response
}

export async function updateLab(id, values) {
  return await sendData(`lab_investigations/${id}`, values, 'PUT')
}

export async function deleteLab(id) {
  return await sendData(`lab_investigations/${id}`, {}, 'DELETE')
}

export async function addLab(values) {
  return await sendData('lab_investigations', values)
}
