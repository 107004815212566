import { useContext, useEffect } from 'react'
import { Row, Col, Input, Label } from 'reactstrap'
import RiskFactorContext from 'store/riskFactorContext'
import CustomSelect from '../elements/customSelect'
import CustomDatePicker from '../elements/customDatePicker'
import FormGroup from '../layouts/formGroup'
import HouseholdMember from './householdMember'
import { gender, martialStatus } from 'mock/patient-form-options'
import {
  educationOptions,
  typeOptions,
  vaccinatedOpt,
  vaccineOpt,
  vaccineDoseOpt,
  localTravelOpt,
  internationalTravelOpt,
  contactWith19Opt,
} from 'mock/patient-form-options'
import { nations } from '../../mock/patient-form-options'

const InfoForm = ({ formik, household, setHouseholds, isEdit }) => {
  const riskFactorCtx = useContext(RiskFactorContext)

  useEffect(() => {
    async function loadApi() {
      await riskFactorCtx.getData()
    }
    loadApi()
  }, [])

  return (
    <>
      <FormGroup title="Basic Info">
        <Row>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Type</Label>
            <CustomSelect
              options={typeOptions}
              onChange={({ value }) => formik.setFieldValue('type', value)}
              value={parseInt(formik.values.type)}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Code</Label>
            <Input
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Register Date</Label>
            <CustomDatePicker
              name="register_date"
              value={formik.values.register_date}
              onChange={(day) => formik.setFieldValue('register_date', day)}
              disabled={isEdit}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Name</Label>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Age</Label>
            <Input
              name="age"
              onChange={formik.handleChange}
              value={formik.values.age}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Sex</Label>
            <CustomSelect
              options={gender}
              onChange={({ value }) => formik.setFieldValue('sex', value)}
              value={formik.values.sex}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Education</Label>
            <CustomSelect
              options={educationOptions}
              onChange={({ value }) => formik.setFieldValue('education', value)}
              value={formik.values.education}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Address</Label>
            <Input
              name="address"
              onChange={formik.handleChange}
              value={formik.values.address}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Phone Number</Label>
            <Input
              name="phone_number"
              onChange={formik.handleChange}
              value={formik.values.phone_number}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Marital Status</Label>
            <CustomSelect
              options={martialStatus}
              onChange={({ value }) =>
                formik.setFieldValue('marital_status', value)
              }
              value={formik.values.marital_status}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Occupation</Label>
            <Input
              name="occupation"
              onChange={formik.handleChange}
              value={formik.values.occupation}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Risk Factors</Label>
            <CustomSelect
              multi={true}
              options={riskFactorCtx.data.map((element) => {
                return {
                  value: element.name.toLowerCase(),
                  label: element.name,
                }
              })}
              onChange={(value) => formik.setFieldValue('risk_factors', value)}
              value={formik.values.risk_factors}
              all={false}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Risk Factor Other</Label>
            <Input
              name="risk_factor_other"
              onChange={formik.handleChange}
              value={formik.values.risk_factor_other}
              disabled={!formik.values.risk_factors.includes('others')}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label> Vaccinated *</Label>
            <CustomSelect
              options={vaccinatedOpt}
              onChange={({ value }) => {
                formik.setFieldValue('vaccinated', value)
              }}
              value={formik.values.vaccinated}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label> Vaccine: Name</Label>
            <CustomSelect
              options={vaccineOpt}
              onChange={({ value }) => {
                formik.setFieldValue('vaccine_name', value)
              }}
              value={formik.values.vaccine_name}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label> Vaccine: Dose</Label>
            <CustomSelect
              options={vaccineDoseOpt}
              onChange={({ value }) => {
                formik.setFieldValue('vaccine_doses', value)
              }}
              value={formik.values.vaccine_doses}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Date of last COVID-19 vaccine dose</Label>
            <CustomDatePicker
              name="last_vaccine_date"
              value={formik.values.last_vaccine_date}
              onChange={(day) => formik.setFieldValue('last_vaccine_date', day)}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label> History of contact with known COVID-19 case</Label>
            <CustomSelect
              options={contactWith19Opt}
              onChange={({ value }) => {
                formik.setFieldValue('contact_with_19', value)
              }}
              value={formik.values.contact_with_19}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label> Place of contact with Covid19 case</Label>
            <Input
              name="contact_with_19_place"
              onChange={formik.handleChange}
              value={formik.values.contact_with_19_place}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Local Travel</Label>
            <CustomSelect
              options={localTravelOpt}
              onChange={({ value }) => {
                formik.setFieldValue('local_travel', value)
              }}
              value={formik.values.local_travel}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>International Travel</Label>
            <CustomSelect
              options={nations.map((e) => ({ label: e, value: e }))}
              onChange={({ value }) => {
                formik.setFieldValue('international_travel', value)
              }}
              value={formik.values.international_travel}
            />
          </Col>
          <Col md={4} sm={6} xs={12} className="form-group-item">
            <Label>Remark</Label>
            <Input
              name="remark"
              onChange={formik.handleChange}
              value={formik.values.remark}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup title="Household Member">
        <HouseholdMember household={household} setHouseholds={setHouseholds} />
      </FormGroup>
    </>
  )
}

export default InfoForm
