import { getData, sendData } from './apiService.js'
export async function getWarehouseIssues(params = {}) {
  let response = await getData(`warehouseissues`, params)
  return response
}
export async function getWarehouseIssue(id) {
  let response = await getData(`warehouseissues/${id}`)
  return response
}
export async function addWarehouseIssue(values) {
  return await sendData('warehouseissues', values)
}
export async function editWarehouseIssue(values, id) {
  return await sendData(`warehouseissues/${id}`, values, 'PUT')
}

export async function deleteWarehouseIssue(id) {
  return await sendData(`warehouseissues/${id}`, {}, 'DELETE')
}
