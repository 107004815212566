import { Row, Col, Label, Input } from 'reactstrap'
import CustomDatePicker from '../elements/customDatePicker'
import FormGroup from '../layouts/formGroup'
const DetailForm = ({ formik }) => {
  return (
    <FormGroup title="Item Information">
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Expire Date</Label>
          <CustomDatePicker
            name="expire_date"
            value={formik.values.expire_date}
            onChange={(day) => formik.setFieldValue('expire_date', day)}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Batch Number</Label>
          <Input
            name="batch_number"
            onChange={formik.handleChange}
            value={formik.values.batch_number}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}
export default DetailForm
