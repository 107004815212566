import { Form, Formik } from 'formik'
import { Row, Col, Button } from 'reactstrap'
import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import AdminLayout from 'layouts/admin'
import InfoForm from 'components/visit/infoForm'
import CovidForm from 'components/visit/covidForm'
import LinkButtons from 'components/visit/linkButtons'
import SymptonContext from 'store/symptonContext'
import PatientContext from 'store/patientContext'
import VisitContext from 'store/visitContext'

const VisitDetail = () => {
  const [initalValues, setInitValues] = useState({
    visit: {
      date: '',
      onset_symptons: [],
      onset_symptons_date: '',
      onset_symptons_other: '',
      vaccinated: '',
      vaccine_name: '',
      vaccine_doses: '',
      last_vaccine_date: '',
      contact_with_19: '',
      contact_with_19_place: '',
      local_travel: '',
      international_travel: '',
      covid_test: '',
      covid_date: '',
      covid_result: '',
      covid_test_type: '',
      covid_township_self: '',
      covid_township_option: '',
    },
  })
  const symptonCtx = useContext(SymptonContext)
  const patientCtx = useContext(PatientContext)
  const visitCtx = useContext(VisitContext)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const submitData = async (values) => {
    await visitCtx.editData(values, id)
  }

  useEffect(() => {
    async function fetchApi() {
      setLoading(true)
      await symptonCtx.getData()
      let visit = await visitCtx.getEachData(id)
      await patientCtx.getEachData(visit.patient_id)
      setInitValues({ visit: visit })
      setLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <AdminLayout>
      {!loading && (
        <>
          <PatientContext.Consumer>
            {({ eachData }) => (
              <h5>
                Visit of {eachData?.name} ({eachData?.unique_code})
              </h5>
            )}
          </PatientContext.Consumer>
          <Formik
            initialValues={initalValues}
            validate={(values) => {
              const errors = {}
              return errors
            }}
            onSubmit={(values) => submitData(values)}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <InfoForm isEdit={true} formik={formik} />
                <CovidForm formik={formik} />
                <Row className="mt-3">
                  <Col sm={{ size: 2, offset: 5 }} xs={{ size: 8, offset: 2 }}>
                    <Button className="submit-btn mb-3" color="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <LinkButtons visitID={id} />
        </>
      )}
    </AdminLayout>
  )
}
export default VisitDetail
