import Home from './../pages/admin/home'
import NewPatient from './../pages/admin/patient/new'
import EditPatient from './../pages/admin/patient/edit'
import PatientDetail from './../pages/admin/patient/detail'
import NewVisit from './../pages/admin/visit/new'
import VisitDetail from './../pages/admin/visit/detail'
import DailyMonitoring from './../pages/admin/dailymonitoring'
import Lab from './../pages/admin/lab'
import LabDetail from './../pages/admin/lab/detail'
import NewLab from './../pages/admin/lab/new'

export const adminRoutes = [
  {
    path: '/',
    title: 'Home',
    component: Home,
  },
  {
    path: '/new-patient',
    title: 'NewPatient',
    component: NewPatient,
  },

  {
    path: '/patient/:id',
    title: 'PatientDetail',
    component: PatientDetail,
  },
  {
    path: '/edit-patient/:id',
    title: 'EditPatient',
    component: EditPatient,
  },
  {
    path: '/new-visit/:patient_id',
    title: 'NewVisit',
    component: NewVisit,
  },
  {
    path: '/visit/:id',
    title: 'VisitDetail',
    component: VisitDetail,
  },
  {
    path: '/daily-monitoring/:visit_id',
    title: 'DailyMonitoring',
    component: DailyMonitoring,
  },
  {
    path: '/lab/:visit_id',
    title: 'Lab',
    component: Lab,
  },
  {
    path: '/lab-detail/:id',
    title: 'LabDetail',
    component: LabDetail,
  },
  {
    path: '/new-lab/:visit_id',
    title: 'NewLab',
    component: NewLab,
  },
]
