import { Button } from "reactstrap";
import "./not-found.scss";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src="http://24.media.tumblr.com/61f12406b4f0f01e4354e182a0533b36/tumblr_mh1gdoIRjl1rgpyeqo1_500.gif" />
      <p>Page Not Found</p>
      <Button>Get Back To Home</Button>
    </div>
  );
};
export default NotFound;
