import { getData, sendData } from "./apiService.js";
export async function getVisits(params = {}) {
  let response = await getData("visits", params);
  return response;
}

export async function getVisit(id) {
  let response = await getData(`visits/${id}`);
  return response;
}

export async function addVisit(values) {
  return await sendData("visits", values);
}

export async function editVisit(values, id) {
  return await sendData(`visits/${id}`, values, "PUT");
}
