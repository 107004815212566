import { useContext } from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import { Trash } from 'react-bootstrap-icons'
import FormGroup from '../../layouts/formGroup'
import ItemContext from 'store/itemContext'
const WhItemManager = ({ items, removeItem, changeQty, status }) => {
  const itemCtx = useContext(ItemContext)
  return (
    <FormGroup title="Requested Items">
      <Row className="mb-2">
        <Col md={6} xs={7}>
          Name
        </Col>
        <Col md={3} xs={3}>
          Qty
        </Col>
        <Col xs={2}>Actions</Col>
      </Row>
      {items?.map((item, index) => {
        return (
          <Row className="mb-2">
            <Col md={6} xs={7}>
              <Input
                value={`${itemCtx.data.find((e) => e.id === item.id)?.item} ${
                  itemCtx.data.find((e) => e.id === item.id)?.au
                }`}
                disabled
              />
            </Col>
            <Col md={3} xs={3}>
              <Input
                min="1"
                type="number"
                value={item.qty}
                onChange={(e) => changeQty(index, e.target.value)}
                onBlur={(e) => {
                  if (e.target.value[0] === '0') changeQty(index, 1)
                }}
              />
            </Col>
            <Col xs={2}>
              <Button
                color="danger"
                onClick={() => removeItem(item.id)}
                disabled={status !== 'pending'}
              >
                <Trash />
              </Button>
            </Col>
          </Row>
        )
      })}
    </FormGroup>
  )
}
export default WhItemManager
