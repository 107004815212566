import { Formik } from "formik";
import { useContext, useState } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  CardTitle,
  Button,
} from "reactstrap";
import AuthContext from "../../../store/authContext";
import "./index.scss";
const Login = () => {
  let initalValues = {
    name: "",
    password: "",
  };
  const { login, loading } = useContext(AuthContext);

  const submitData = async (values) => {
    await login(values);
  };

  return (
    <>
      <div className="login-container">
        <Card className="login-container__card">
          <CardTitle>Covid Recording System</CardTitle>
          <CardBody className="login-container__card__body">
            <Formik
              initialValues={initalValues}
              validate={(values) => {
                const errors = {};
                if (!values.name) errors.name = "** Required **";
                if (!values.password) errors.password = "** Required **";
                return errors;
              }}
              onSubmit={(values) => submitData(values)}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <Label for="nameLabel">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="nameLabel"
                      placeholder="Enter your user name..."
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    <span className="text-danger">
                      {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="passwordLabel">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="passwordLabel"
                      placeholder="Enter your password..."
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <span className="text-danger">
                      {formik.errors.password &&
                        formik.touched.password &&
                        formik.errors.password}
                    </span>
                  </FormGroup>
                  <div class="text-center">
                    <Button color="primary" type="submit" disabled={loading}>
                      Login
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default Login;
