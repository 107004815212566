import { useContext, useEffect } from 'react'
import { CardHeading, FileEarmarkPerson, PlusLg } from 'react-bootstrap-icons'
import { Row, Col, Card } from 'reactstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import PatientContext from 'store/patientContext'
import AdminLayout from 'layouts/admin'
import './detail.scss'
const PatientDetail = () => {
  const patientCtx = useContext(PatientContext)
  const { id } = useParams()
  const history = useHistory()

  const addNewVisit = () => {
    if (patientCtx.eachData.visits.length == 0)
      history.push(`/admin/new-visit/${id}`)
    else {
      const lastVisit = patientCtx.eachData.visits.slice(-1)[0]
      if (lastVisit.outcome) history.push(`/admin/new-visit/${id}`)
      else NotificationManager.warning("Patient's Last Visit is not finished")
    }
  }

  useEffect(() => {
    async function fetchApi() {
      await patientCtx.getEachData(id)
    }
    fetchApi()
  }, [])

  return (
    <AdminLayout>
      <PatientContext.Consumer>
        {({ eachData }) => (
          <Row className="patient-detail">
            <Col md={{ size: 8, offset: 2 }} xs={{ size: 12, offset: 0 }}>
              <Card className="patient-info-card">
                <Row>
                  <Col sm={6} xs={12}>
                    <h5>Name: {eachData?.name}</h5>
                  </Col>
                  <Col sm={6} xs={12}>
                    <h5>Code : {eachData?.unique_code}</h5>
                  </Col>
                </Row>
              </Card>
              <Row className="mt-2">
                <Col md={6} xs={12}>
                  <Link to={`/admin/edit-patient/${eachData.id}`}>
                    <Card className="patient-detail__button-card">
                      <FileEarmarkPerson size="55" />
                      <h4>Edit Info</h4>
                    </Card>
                  </Link>
                </Col>
                {eachData.visits?.map((element, index) => (
                  <Col md={6} xs={12}>
                    <Link to={`/admin/visit/${element.id}`}>
                      <Card className="patient-detail__button-card">
                        <CardHeading size="55" />
                        <h4>Visit {index + 1}</h4>
                      </Card>
                    </Link>
                  </Col>
                ))}

                <Col md={6} xs={12}>
                  <Card
                    className="patient-detail__button-card"
                    onClick={addNewVisit}
                  >
                    <PlusLg size="55" />
                    <h4>Add First Visit</h4>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </PatientContext.Consumer>
    </AdminLayout>
  )
}

export default PatientDetail
