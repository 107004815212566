import React, { useContext } from 'react'
import { Table, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import FormGroup from 'components/layouts/formGroup'
import StockManageForm from 'components/item/stockManageForm'
import IssueManageForm from 'components/item/issueManageForm'
import AuthContext from 'store/authContext'
import WarehouseReceiveContext from 'store/warehouseReceiveContext'
import TownshipStoreIssueOutContext from 'store/townshipStoreIssueOutContext'
import WarehouseIssueOutContext from 'store/warehouseIssueOutContext'
import './stockControl.scss'
export default function StockControl({ warehouse, itemID }) {
  const { data: user } = useContext(AuthContext)
  const { addData: addWhReceive } = useContext(WarehouseReceiveContext)
  const { addData: addIssueOut } = useContext(WarehouseIssueOutContext)
  const { addData: addTownshipIssueOut } = useContext(
    TownshipStoreIssueOutContext
  )
  const onAddReceive = async (values) => {
    var data = {
      item: values,
    }
    data.item.item_id = itemID
    await addWhReceive(data)
  }

  const onAddIssueOut = async (values) => {
    if (user.role === 'warehouse') {
      var data = {
        issue_item: values,
      }
      data.issue_item.item_id = itemID
      data.issue_item.is_issue_damage = true
      data.issue_item.issue_damage_date = values.date
      await addIssueOut(data)
      window.location.reload()
    } else {
      var data = {
        township_store_id: itemID,
        quantity: values.quantity,
        is_issue_damage: true,
        issue_damage_date: values.date,
      }
      await addTownshipIssueOut({ dispence_item: data })
      window.location.reload()
    }
  }

  return (
    <FormGroup title="Stock Control">
      <Row>
        <Table className="custom-body" bordered>
          <tr>
            <th>Opening Balance</th>
            <th>Recieved</th>
            <th>Issued</th>
            <th>Issue Out (Damage)</th>
            <th>Closing Balance</th>
          </tr>
          <tr>
            <td>{warehouse?.opening_balance}</td>
            <td>
              <Link
                to={user.role === 'warehouse' ? `/wh/receives/${itemID}` : '#'}
              >
                {warehouse?.total_receive}
              </Link>
            </td>
            <td>{warehouse?.total_issue}</td>
            <td>
              <Link
                to={
                  user.role === 'warehouse' ? `/wh/issue_outs/${itemID}` : '#'
                }
              >
                {warehouse?.total_issue_out}
              </Link>
            </td>
            <td>{warehouse?.closing_balance}</td>
          </tr>
        </Table>
      </Row>
      <Row className="mt-2">
        <Col md="6">
          {user.role == 'warehouse' && (
            <StockManageForm label="To Add Receive" onSubmit={onAddReceive} />
          )}
        </Col>
        <Col md="6">
          <IssueManageForm
            label="To Add Issue (out)"
            onSubmit={onAddIssueOut}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}
