import { useHistory } from 'react-router'
import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { itemRequestData, eachItemReq } from './../mock/item'
import {
  getItemRequests,
  getItemRequest,
  addItemRequest,
  editItemRequest,
  deleteItemRequest,
} from 'api/itemRequest'

const ItemRequestContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})

const giveObject = (obj) => {
  obj.items = JSON.parse(obj.items)
  if (obj.approved_items) obj.approved_items = JSON.parse(obj.approved_items)
  else obj.approved_items = []
  return obj
}

export function ItemRequestContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const history = useHistory()
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      // setItems(await getItemRequests(params));
    },
    getEachData: async (id) => {
      let response = await getItemRequest(id)
      setItem(giveObject(response))
      return response
    },
    addData: async (values) => {
      if (await addItemRequest(values)) {
        NotificationManager.success('Success')
        history.push(`/user/item-request/${values.item_request.visit_id}`)
      }
    },
    editData: async (values, id) => {
      if (await editItemRequest(values, id)) {
        NotificationManager.success('Success')
        history.push('/wh')
      }
    },
    deleteData: async (id, visit_id) => {
      if (await deleteItemRequest(id)) {
        NotificationManager.success('Success')
        history.push(`/user/item-request/${visit_id}`)
      }
    },
  }
  return (
    <ItemRequestContext.Provider value={context}>
      {props.children}
    </ItemRequestContext.Provider>
  )
}

export default ItemRequestContext
