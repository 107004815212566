export const labData = [
  { id: 1, day: 'Day 1 ', date: '2020-02-02', orders: 4 },
  { id: 2, day: 'Day 2 ', date: '2020-04-02', orders: 2 },
  { id: 3, day: 'Day 3 ', date: '2020-05-02', orders: 2 },
]

export const labStatus = [
  { value: 'not done', label: 'Not Done' },
  { value: 'done', label: 'Done' },
]

export const hmUnit = [
  { value: 'mmol', label: 'mmol' },
  { value: 'mg', label: 'mg' },
]

export const labOrderData = [
  { id: 1, name: 'CXR' },
  { id: 2, name: 'LFT' },
]

export const labOrderHeaderData = [
  { id: 1, name: 'CXR' },
  { id: 2, name: 'Haemogram' },
  { id: 3, name: 'LFT' },
  { id: 4, name: 'D Dimer' },
  { id: 5, name: 'RFT ' },
]

export const liverFunctionOpt = [
  {
    value: {
      name: 'AST',
      unit: 'U/L',
    },
    label: 'AST',
  },
  {
    value: {
      name: 'ALT',
      unit: 'U/L',
    },
    label: 'ALT',
  },
  {
    value: {
      name: 'ALP',
      unit: 'U/L',
    },
    label: 'ALP',
  },
  {
    value: {
      name: 'Bilirubin',
      unit: 'µmol/L',
    },
    label: 'Bilirubin',
  },
]

export const fullBloodCountOpt = [
  {
    value: {
      name: 'HGB',
      unit: 'g/dL',
    },
    label: 'HGB',
  },
  {
    value: {
      name: 'WBC',
      unit: '10^9/L',
    },
    label: 'WBC',
  },
  {
    value: {
      name: 'Lymph%',
      unit: '%',
    },
    label: 'Lymph%',
  },
  {
    value: {
      name: 'Lymph#',
      unit: '10^9/L',
    },
    label: 'Lymph#',
  },
  {
    value: {
      name: 'PLT',
      unit: '10^9/L',
    },
    label: 'PLT',
  },
]

export const rftOpt = [
  {
    value: {
      name: 'Urea',
      unit: 'mmol/L',
    },
    label: 'Urea',
  },
  {
    value: {
      name: 'Sodium',
      unit: 'mmol/L',
    },
    label: 'Sodium',
  },
  {
    value: {
      name: 'Potassium',
      unit: 'mmol/L',
    },
    label: 'Potassium',
  },
  {
    value: {
      name: 'Chloride',
      unit: 'mmol/L',
    },
    label: 'Chloride',
  },
  {
    value: {
      name: 'Bicarbonate',
      unit: 'mmol/L',
    },
    label: 'Bicarbonate',
  },
  {
    value: {
      name: 'Phosphorous',
      unit: 'md/dL',
    },
    label: 'Phosphorous',
  },
  {
    value: {
      name: 'Creatinine',
      chooseUnit: true,
      units: ['µmol/L', '(mg/dL)'],
      unit: '',
    },
    label: 'Creatinine',
  },
]

export const additionalOpt = [
  {
    value: {
      name: 'D-Dimmer',
      unit: 'µg/mL',
    },
    label: 'D-Dimmer',
  },
  {
    value: {
      name: 'LDH',
      unit: 'U/L',
    },
    label: 'LDH',
  },
  {
    value: {
      name: 'Ferritin',
      unit: 'µg/L',
    },
    label: 'Ferritin',
  },
  {
    value: {
      name: 'C-Reactive Protein',
      unit: 'mg/dL',
    },
    label: 'C-Reactive Protein',
  },
  {
    value: {
      name: 'Procalcitonin',
      chooseUnit: true,
      units: ['ng/ml', '(µg/L)'],
      unit: '',
    },
    label: 'Procalcitonin',
  },
  {
    value: {
      name: 'Uric Acid',
      unit: 'mg/dL',
    },
    label: 'Uric Acid',
  },
  {
    value: {
      name: 'PT',
      unit: 'Sec',
    },
    label: 'PT',
  },
  {
    value: {
      name: 'INR',
      unit: '',
    },
    label: 'INR',
  },
  {
    value: {
      name: 'Albumin',
      unit: 'g/dL',
    },
    label: 'Albumin',
  },
  {
    value: {
      name: 'Total Protein',
      unit: 'g/dL',
    },
    label: 'Total Protein',
  },
]

export const otherXOpt = [
  {
    value: {
      name: 'X-ray',
    },
    label: 'X-ray',
  },
  {
    value: {
      name: 'Ultransound',
    },
    label: 'Ultransound',
  },
  {
    value: {
      name: 'CT',
    },
    label: 'CT',
  },
]
