import { useContext } from 'react'
import { Card, Button } from 'reactstrap'
import { Pencil } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import ItemContext from 'store/itemContext'
import LoadingContext from 'store/loadingContext'
import AuthContext from 'store/authContext'
import './item-list.scss'

const ItemList = () => {
  const { editData } = useContext(ItemContext)
  const { actionLoading, addActionLoading } = useContext(LoadingContext)
  const { data: userData } = useContext(AuthContext)

  const changeStatus = async (item, status) => {
    if (!actionLoading) {
      addActionLoading(true)
      item.active = status
      await editData(item, item.id)
      addActionLoading(false)
    }
  }

  return (
    <>
      <ItemContext.Consumer>
        {({ data }) =>
          data.map((element, index) => (
            <Card className="item-list-item">
              <div className="item-list-item__info">
                <span className="item-list-item__info__no">{index + 1}.</span>
                <span className="item-list-item__info__title">
                  {element.item} {element.au}
                </span>
              </div>
              {userData.role == 'warehouse' && (
                <div className="item-list-item__actions">
                  <Link to={`/wh/edit-item/${element.id}`}>
                    <Button
                      className="mr-2"
                      color="success"
                      style={{ marginRight: '10px' }}
                      disabled={actionLoading}
                    >
                      <Pencil />
                    </Button>
                  </Link>
                  {element.active === 1 && (
                    <span
                      className="bg-success"
                      onClick={() => changeStatus(element, 0)}
                    >
                      A
                    </span>
                  )}
                  {element.active === 0 && (
                    <span
                      className="bg-danger"
                      onClick={() => changeStatus(element, 1)}
                    >
                      N
                    </span>
                  )}
                </div>
              )}
              {userData.role != 'warehouse' && (
                <div className="item-list-item__actions">
                  <Link to={`/user/edit-item/${element.id}`}>
                    <Button
                      className="mr-2"
                      color="success"
                      style={{ marginRight: '10px' }}
                      disabled={actionLoading}
                    >
                      <Pencil />
                    </Button>
                  </Link>
                  <span className="bg-success">5</span>
                </div>
              )}
            </Card>
          ))
        }
      </ItemContext.Consumer>
    </>
  )
}
export default ItemList
