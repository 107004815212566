import { useEffect, useContext, useState } from 'react'
import { Card, Col, Row, Button } from 'reactstrap'
import FormGroup from 'components/layouts/formGroup'
import { Link } from 'react-router-dom'
import UserLayout from 'layouts/user'
import LabContext from 'store/labContext'
import PatientContext from 'store/patientContext'
import './index.scss'
const Lab = (props) => {
  const [loading, setLoading] = useState(false)
  const labCtx = useContext(LabContext)

  useEffect(() => {
    async function fetchApi() {
      setLoading(true)
      await labCtx.getData(props.match.params.visit_id)

      setLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      {!loading && (
        <>
          <Row>
            <Col xs={{ size: 12 }} style={{ textAlign: 'right' }}>
              <Link to={`/user/new-lab/${props.match.params.visit_id}`}>
                <Button color="primary" outline>
                  Add New
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="lab-data-container">
            <LabContext.Consumer>
              {({ data }) =>
                data.map((element, index) => (
                  <Link to={`/user/lab-detail/${element?.id}`}>
                    <Card className="lab-data-item">
                      <div className="lab-data-item__info">
                        <span className="lab-data-item__info__no">
                          {index + 1}.
                        </span>
                        <span className="lab-data-item__info__title">
                          {element?.date}
                        </span>
                      </div>
                    </Card>
                  </Link>
                ))
              }
            </LabContext.Consumer>
          </Row>
        </>
      )}
    </UserLayout>
  )
}
export default Lab
