import { Row, Col, Label, Input } from 'reactstrap'
import FormGroup from '../layouts/formGroup'
const EditForm = ({ formik, disabled = false }) => {
  return (
    <FormGroup title="Item Information">
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Item Name</Label>
          <Input
            name="item"
            onChange={formik.handleChange}
            value={formik.values.item}
            disabled={disabled}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>AU</Label>
          <Input
            name="au"
            onChange={formik.handleChange}
            value={formik.values.au}
            disabled={disabled}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}
export default EditForm
