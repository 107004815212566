import { useHistory } from 'react-router'
import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { getItems, getItem, addItem, editItem, deleteItem } from 'api/item'
const ItemContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  deleteData: () => {},
})

export function ItemContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const history = useHistory()
  const context = {
    data: items,
    eachData: item,
    getData: async () => {
      setItems(await getItems())
    },
    getEachData: async (id) => {
      const response = await getItem(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      const response = await addItem(values)
      if (response) {
        NotificationManager.success('Success')
        history.push(`/wh/item`)
      }
    },
    editData: async (values, id) => {
      const response = await editItem(values, id)
      if (response) {
        NotificationManager.success('Success')
        let tmpArr = items.map((value) => {
          if (value.id === id) {
            return response
          }
          return value
        })
        setItems(tmpArr)
      }
    },
    deleteData: async (id) => {
      if (await deleteItem(id)) {
        NotificationManager.success('Success')
        history.push(`/wh/item`)
      }
    },
  }
  return (
    <ItemContext.Provider value={context}>
      {props.children}
    </ItemContext.Provider>
  )
}

export default ItemContext
