import { Row, Col, Label, Input } from 'reactstrap'
import FormGroup from '../layouts/formGroup'
import CustomSelect from '../elements/customSelect'
import CustomDatePicker from '../elements/customDatePicker'
import {
  covidResult,
  covidTesting,
  covidTestTypeOpt,
  covidTownshipSelfOpt,
  covidTownshipOpt,
} from 'mock/patient-form-options'
const CovidForm = ({ formik }) => {
  return (
    <FormGroup title="Covid Testing">
      <Row>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label> Covid Test *</Label>
          <CustomSelect
            options={covidTesting}
            onChange={({ value }) => {
              if (value == 'not done') {
                formik.setFieldValue('visit.covid_result', '')
                formik.setFieldValue('visit.covid_date', '')
              }
              formik.setFieldValue('visit.covid_test', value)
            }}
            value={formik.values.visit.covid_test}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label> Covid Test Type</Label>
          <CustomSelect
            options={covidTestTypeOpt}
            onChange={({ value }) => {
              formik.setFieldValue('visit.covid_test_type', value)
            }}
            value={formik.values.visit.covid_test_type}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Covid Date</Label>
          <CustomDatePicker
            name="visit.covid_date"
            value={formik.values.visit.covid_date}
            onChange={(day) => formik.setFieldValue('visit.covid_date', day)}
            disabled={formik.values.visit.covid_test !== 'done'}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Covid Result</Label>
          <CustomSelect
            options={covidResult}
            onChange={({ value }) =>
              formik.setFieldValue('visit.covid_result', value)
            }
            value={formik.values.visit.covid_result}
            isDisabled={formik.values.visit.covid_test !== 'done'}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Township / Self</Label>
          <CustomSelect
            options={covidTownshipSelfOpt}
            onChange={({ value }) => {
              formik.setFieldValue('visit.covid_township_self', value)
            }}
            value={formik.values.visit.covid_township_self}
            isDisabled={formik.values.visit.covid_result !== 'positive'}
          />
        </Col>
        <Col md={4} sm={6} xs={12} className="form-group-item">
          <Label>Township Option</Label>
          <CustomSelect
            options={covidTownshipOpt}
            onChange={({ value }) => {
              formik.setFieldValue('visit.covid_township_option', value)
            }}
            value={formik.values.visit.covid_township_option}
            isDisabled={formik.values.visit.covid_township_self !== 'township'}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export default CovidForm
