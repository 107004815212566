import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getWarehouseIssues,
  getWarehouseIssue,
  addWarehouseIssue,
  editWarehouseIssue,
  deleteWarehouseIssue,
} from 'api/warehouseIssue'
const WarehouseIssueContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})
export function WarehouseIssueContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      let response = await getWarehouseIssues(params)
      setItems(response)
      return response
    },
    getEachData: async (id) => {
      let response = await getWarehouseIssue(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      if (await addWarehouseIssue(values)) {
        NotificationManager.success('Success')
      }
    },
    editData: async (values, id) => {
      if (await editWarehouseIssue(values, id)) {
        NotificationManager.success('Success')
      }
    },
    deleteData: async (id, visit_id) => {
      if (await deleteWarehouseIssue(id)) {
        NotificationManager.success('Success')
      }
    },
  }
  return (
    <WarehouseIssueContext.Provider value={context}>
      {props.children}
    </WarehouseIssueContext.Provider>
  )
}
export default WarehouseIssueContext
