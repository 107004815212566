import { useContext } from 'react'
import { Button } from 'reactstrap'
import { List } from 'react-bootstrap-icons'
import { BoxArrowRight } from 'react-bootstrap-icons'
import './custom-nav.scss'
import AuthContext from 'store/authContext'

const CustomNav = ({ toggleSidebar }) => {
  const { data, logout } = useContext(AuthContext)

  if (data.role !== 'admin')
    return (
      <div className="custom-nav">
        {data.role !== 'warehouse' && (
          <List className="custom-nav__icon" onClick={() => toggleSidebar()} />
        )}
        <div className="custom-nav__brand">
          <p>Covid User Dashboard</p>
          <span>({data.role.toUpperCase()})</span>
          {data.role != 'warehouse' && (
            <span> ({data.township.short_code})</span>
          )}
        </div>
        <div className="custom-nav__actions">
          <Button className="custom-nav__actions__button" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    )

  if (data.role === 'admin')
    return (
      <div className="custom-nav">
        <div className="custom-nav__brand">
          <p>Covid Admin Dashboard</p>
        </div>
        <div className="custom-nav__actions">
          <Button className="custom-nav__actions__button" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    )
}
export default CustomNav
