import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AllProviders } from 'store/providers'
import reportWebVitals from './reportWebVitals'
import 'react-notifications/lib/notifications.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/scss/bootstrap.scss'
import 'react-day-picker/lib/style.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AllProviders>
        <App />
      </AllProviders>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
