import { useContext } from 'react'
import { Form, Formik } from 'formik'
import { Button } from 'reactstrap'
import WhLayout from 'layouts/wh'
import AddForm from 'components/item/addForm'
import ItemContext from 'store/itemContext'
const NewItem = () => {
  let initalValues = {
    psm: '',
    au: '',
    expire_date: '',
    batch_number: '',
    opening_balance: '',
    active: 1,
  }
  const itemCtx = useContext(ItemContext)

  const submitData = async (values) => {
    await itemCtx.addData({ item: values })
  }

  return (
    <WhLayout>
      <h5>New Item</h5>
      <Formik
        initialValues={initalValues}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={(values) => submitData(values)}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <AddForm formik={formik} />
            <div class="text-center">
              <Button color="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </WhLayout>
  )
}
export default NewItem
