export const gender = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
]

export const martialStatus = [
  { label: 'Married', value: 'married' },
  { label: 'Single', value: 'single' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
]

export const educationOptions = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'University', value: 'university' },
  { label: 'Graduate', value: 'graduate' },
  { label: 'Post Graduate', value: 'postGraduate' },
]

export const symptonOptions = [
  { label: 'Sympton 1', value: 'sympton1' },
  { label: 'Sympton 2', value: 'sympton2' },
  { label: 'Sympton 3', value: 'sympton3' },
]

export const covidTesting = [
  { value: 'not done', label: 'Not Done' },
  { value: 'done', label: 'Done' },
]

export const covidResult = [
  { value: 'negative', label: 'Negative' },
  { value: 'positive', label: 'Positive' },
]

export const filledByOpt = [
  { label: 'MD', value: 'md' },
  { label: 'DMT', value: 'dmt' },
]

export const labOrders = [
  { label: 'Haemogram Range', value: 'haemogram range' },
  { label: 'CXR', value: 'cxr' },
  { label: 'LFT', value: 'lft' },
  { label: 'D Dimer', value: 'd dimer' },
  { label: 'RFT', value: 'rft' },
]

export const serviceTypeOpt = [
  { label: 'Care By MD', value: 'care_by_md' },
  { label: 'DMT', value: 'cmt' },
  { label: 'Homebased Care Team', value: 'homebased_care_team' },
  { label: 'Physician', value: 'physician' },
  { label: 'Hospital/ Treatment Center', value: 'hospital/treatment_center' },
]

export const riskFactors = [
  { label: 'Risk Factor 1', value: 'risk_factor_one' },
  { label: 'Risk Factor 2', value: 'risk_factor_two' },
  { label: 'Other', value: 'other' },
]

export const temperateUnitOpt = [
  { label: 'Celsius', value: 'celsius' },
  { label: 'Fahrenheit', value: 'fahrenheit' },
]

export const typeOptions = [
  { label: 'Staff', value: 1 },
  { label: 'Staff Family Member', value: 2 },
  { label: 'TB Patient', value: 3 },
  { label: 'TB Patient Family', value: 4 },
  { label: 'HIV Patient', value: 5 },
  { label: 'HIV Patient Family', value: 6 },
  { label: 'Volunteer', value: 7 },
  { label: 'Volunteer Family', value: 8 },
  { label: 'Other', value: 9 },
]

export const vaccinatedOpt = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

export const vaccineOpt = [
  { label: 'Private vaccination', value: 'Private vaccination' },
  { label: 'Covishield', value: 'Covishield' },
  { label: 'Vero cell', value: 'Vero cell' },
  { label: 'Pfizer', value: 'Pfizer' },
  { label: 'Johnson and Johnson', value: 'Johnson and Johnson' },
  { label: 'Astrazeneca', value: 'Astrazeneca' },
  { label: 'Moderna', value: 'Moderna' },
]

export const vaccineDoseOpt = [
  { label: 'First Dose', value: 'First Dose' },
  { label: 'Second Dose', value: 'Second Dose' },
]

export const localTravelOpt = [
  { label: 'No travel', value: 'No travel' },
  { label: 'Local travel', value: 'Local travel' },
  { label: 'International travel', value: 'International travel' },
]
export const internationalTravelOpt = [
  { label: 'Andorra', value: 'Andorra' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antarctica', value: 'Antarctica' },

  { label: 'Argentina', value: 'Argentina' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Aruba', value: 'Aruba' },

  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Belgium', value: 'Belgium' },
]

export const nations = [
  'Andorra',
  'United Arab Emirates',
  'Afghanistan',
  'Antigua and Barbuda',
  'Anguilla',
  'Albania',
  'Armenia',
  'Netherlands Antilles',
  'Angola',
  'Antarctica',
  'Argentina',
  'American Samoa',
  'Austria',
  'Australia',
  'Aruba',
  'Azerbaijan',
  'Bosnia and Herzegovina',
  'Barbados',
  'Bangladesh',
  'Belgium',
  'Burkina Faso',
  'Bulgaria',
  'Bahrain',
  'Burundi',
  'Benin',
  'Bermuda',
  'Brunei',
  'Bolivia',
  'Brazil',
  'Bahamas',
  'Bhutan',
  'Bouvet Island',
  'Botswana',
  'Belarus',
  'Belize',
  'Canada',
  'Cocos [Keeling] Islands',
  'Congo [DRC]',
  'Central African Republic',
  'Congo [Republic]',
  'Switzerland',
  "C√¥te d'Ivoire",
  'Cook Islands',
  'Chile',
  'Cameroon',
  'China',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Cape Verde',
  'Christmas Island',
  'Cyprus',
  'Czech Republic',
  'Germany',
  'Djibouti',
  'Denmark',
  'Dominica',
  'Dominican Republic',
  'Algeria',
  'Ecuador',
  'Estonia',
  'Egypt',
  'Western Sahara',
  'Eritrea',
  'Spain',
  'Ethiopia',
  'Finland',
  'Fiji',
  'Falkland Islands [Islas Malvinas]',
  'Micronesia',
  'Faroe Islands',
  'France',
  'Gabon',
  'United Kingdom',
  'Grenada',
  'Georgia',
  'French Guiana',
  'Guernsey',
  'Ghana',
  'Gibraltar',
  'Greenland',
  'Gambia',
  'Guinea',
  'Guadeloupe',
  'Equatorial Guinea',
  'Greece',
  'South Georgia and the South Sandwich Islands',
  'Guatemala',
  'Guam',
  'Guinea-Bissau',
  'Guyana',
  'Gaza Strip',
  'Hong Kong',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Croatia',
  'Haiti',
  'Hungary',
  'Indonesia',
  'Ireland',
  'Israel',
  'Isle of Man',
  'India',
  'British Indian Ocean Territory',
  'Iraq',
  'Iran',
  'Iceland',
  'Italy',
  'Jersey',
  'Jamaica',
  'Jordan',
  'Japan',
  'Kenya',
  'Kyrgyzstan',
  'Cambodia',
  'Kiribati',
  'Comoros',
  'Saint Kitts and Nevis',
  'North Korea',
  'South Korea',
  'Kuwait',
  'Cayman Islands',
  'Kazakhstan',
  'Laos',
  'Lebanon',
  'Saint Lucia',
  'Liechtenstein',
  'Sri Lanka',
  'Liberia',
  'Lesotho',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Libya',
  'Morocco',
  'Monaco',
  'Moldova',
  'Montenegro',
  'Madagascar',
  'Marshall Islands',
  'Macedonia [FYROM]',
  'Mali',
  'Myanmar [Burma]',
  'Mongolia',
  'Macau',
  'Northern Mariana Islands',
  'Martinique',
  'Mauritania',
  'Montserrat',
  'Malta',
  'Mauritius',
  'Maldives',
  'Malawi',
  'Mexico',
  'Malaysia',
  'Mozambique',
  'Namibia',
  'New Caledonia',
  'Niger',
  'Norfolk Island',
  'Nigeria',
  'Nicaragua',
  'Netherlands',
  'Norway',
  'Nepal',
  'Nauru',
  'Niue',
  'New Zealand',
  'Oman',
  'Panama',
  'Peru',
  'French Polynesia',
  'Papua New Guinea',
  'Philippines',
  'Pakistan',
  'Poland',
  'Saint Pierre and Miquelon',
  'Pitcairn Islands',
  'Puerto Rico',
  'Palestinian Territories',
  'Portugal',
  'Palau',
  'Paraguay',
  'Qatar',
  'R√©union',
  'Romania',
  'Serbia',
  'Russia',
  'Rwanda',
  'Saudi Arabia',
  'Solomon Islands',
  'Seychelles',
  'Sudan',
  'Sweden',
  'Singapore',
  'Saint Helena',
  'Slovenia',
  'Svalbard and Jan Mayen',
  'Slovakia',
  'Sierra Leone',
  'San Marino',
  'Senegal',
  'Somalia',
  'Suriname',
  'S√£o Tom√© and Pr√≠ncipe',
  'El Salvador',
  'Syria',
  'Swaziland',
  'Turks and Caicos Islands',
  'Chad',
  'French Southern Territories',
  'Togo',
  'Thailand',
  'Tajikistan',
  'Tokelau',
  'Timor-Leste',
  'Turkmenistan',
  'Tunisia',
  'Tonga',
  'Turkey',
  'Trinidad and Tobago',
  'Tuvalu',
  'Taiwan',
  'Tanzania',
  'Ukraine',
  'Uganda',
  'U.S. Minor Outlying Islands',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vatican City',
  'Saint Vincent and the Grenadines',
  'Venezuela',
  'British Virgin Islands',
  'U.S. Virgin Islands',
  'Vietnam',
  'Vanuatu',
  'Wallis and Futuna',
  'Samoa',
  'Kosovo',
  'Yemen',
  'Mayotte',
  'South Africa',
  'Zambia',
  'Zimbabwe',
]

export const contactWith19Opt = [
  { label: 'No contact', value: 'No contact' },
  {
    label: 'Contact with COVID-19 patients',
    value: 'Contact with COVID-19 patients',
  },
]

export const covidTestTypeOpt = [
  { label: 'RDT', value: 'RDT' },
  { label: 'RT-PCR', value: 'RT-PCR' },
  { label: 'Gene Xpert', value: 'Gene Xpert' },
]

export const covidTownshipSelfOpt = [
  { label: 'Self', value: 'self' },
  { label: 'Township', value: 'township' },
]

export const covidTownshipOpt = [
  { label: 'Pilot testing', value: 'Pilot testing' },
  { label: 'Hospital', value: 'Hospital' },
  { label: 'Q Centre', value: 'Q Centre' },
  { label: 'THD', value: 'THD' },
]

export const finalClassificationOpt = [
  { label: 'Mild', value: 'Mild' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'Severe', value: 'Severe' },
  { label: 'Critical', value: 'Critical' },
]
