import React,{useContext} from "react";
import { withRouter } from "react-router";
import LoadingContext from "store/loadingContext";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import CustomNav from "components/layouts/customNav.js";
import "./wh.scss";
const WhLayout = ({ children }) => {
  const { loading } = useContext(LoadingContext);
  return (
    <>
      <CustomNav />
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader color="blue" />}
        text="Loading.."
      >
        {!loading && <div className="main-content">{children}</div> }
      </LoadingOverlay>
    </>
  );
};

export default withRouter(WhLayout);
