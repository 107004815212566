export function convertToServerFormat(date) {
  var obj = new Date(date);
  return `${obj.getDate()}/${obj.getMonth() + 1}/${obj.getFullYear()}`;
}

export function getTodayDate() {
  let obj = new Date();
  let date = obj.getDate();
  let month = obj.getMonth() + 1;
  let year = obj.getFullYear();
  date = date < 10 ? `0${date}` : date;
  month = month < 10 ? `0${month}` : month;
  return `${date}/${month}/${year}`;
}

export function getCurrentTime() {
  const currentDate = new Date();
  let currentHour =
    currentDate.getHours() > 12
      ? currentDate.getHours() - 12
      : currentDate.getHours();
  currentHour = currentHour < 10 ? `0${currentHour}` : currentHour;

  let currentMin =
    currentDate.getMinutes() < 10
      ? `0${currentDate.getMinutes()}`
      : currentDate.getMinutes();
  let diff = currentDate.getHours() > 12 ? "PM" : "AM";

  return `${currentHour}:${currentMin}:${diff}`;
}
