import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import UserLayout from 'layouts/user'
import EditForm from 'components/item/editForm'
import StockControl from 'components/item/stockControl'
import TownshipStoreContext from 'store/townshipStoreContext'
import LoadingContext from 'store/loadingContext'

const EditItem = () => {
  const [initalValues, setInitalValues] = useState({})
  const { getEachData: getTownshipStore, eachData: townshipStore } =
    useContext(TownshipStoreContext)
  const { addLoading } = useContext(LoadingContext)
  const { id } = useParams()

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      let data = await getTownshipStore(id)
      setInitalValues(data.item)
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <h5>Edit Item</h5>
      <Formik initialValues={initalValues}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <EditForm formik={formik} disabled={true} />
          </Form>
        )}
      </Formik>
      <div className="mt-3">
        <StockControl warehouse={townshipStore} itemID={id} />
      </div>
    </UserLayout>
  )
}
export default EditItem
