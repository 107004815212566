import { useContext, useEffect } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import Item from 'components/item-request/item'
import WhLayout from 'layouts/wh'
import BreadCrumb from 'components/elements/breadcrumb'
import WarehouseIssueContext from 'store/warehouseIssueContext'
import LoadingContext from 'store/loadingContext'
import './index.scss'

const Home = () => {
  //it's just sample array for breadcrumbs
  //u can add component or separate as a breadcrumb file
  const breadcrumbs = [
    {
      path: '',
      name: 'Sample 1',
    },
    {
      path: '',
      name: 'Sample 2',
    },
  ]
  const { getData: getIssues, data: issues } = useContext(WarehouseIssueContext)
  const { addLoading } = useContext(LoadingContext)

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await getIssues()
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <WhLayout>
      {/* <BreadCrumb breadcrumbs={breadcrumbs} /> */}
      <Row>
        <Col md={6} sm={8} xs={12}>
          <h5>Item Issues</h5>
        </Col>
        <Col md={6} sm={8} xs={12}>
          <Link to="/wh/new-item-request">
            <Button color="primary" outline style={{ marginLeft: '5px' }}>
              Submit Items
            </Button>
          </Link>
          {/* <Link to="/wh/historical">
            <Button color="primary" outline style={{ marginLeft: '5px' }}>
              Historial Items
            </Button>
          </Link> */}
          <Link to="/wh/item">
            <Button color="primary" outline style={{ marginLeft: '5px' }}>
              Item Lists
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="item-request-container">
        {issues?.map((element, index) => {
          return (
            <Link to={`/wh/request/${element.id}`}>
              <Item element={element} index={index} showTwp={true} />
            </Link>
          )
        })}
      </Row>
    </WhLayout>
  )
}
export default Home
