import { useContext, useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useParams } from 'react-router'
import UserLayout from 'layouts/user'
import ReviewPage from 'components/dispense/reivewPage'
import LoadingContext from 'store/loadingContext'
import PatientContext from 'store/patientContext'
import ItemContext from 'store/itemContext'
import ItemDispensingContext from 'store/itemDispensingContext'
const ReviewDispense = () => {
  const [selectedItems, setSelectedItem] = useState([])
  const { addLoading, addActionLoading, actionLoading } =
    useContext(LoadingContext)
  const itemCtx = useContext(ItemContext)
  const patientCtx = useContext(PatientContext)
  const { getEachData: getDispense, eachData: dispense } = useContext(
    ItemDispensingContext
  )
  const { id } = useParams()

  useEffect(() => {
    async function fetchApi() {
      addLoading(true)
      await itemCtx.getData()
      await patientCtx.getData()
      let response = await getDispense(id)
      let items = response.township_store_dispenced_items.map((element) => ({
        id: {
          label:
            element.township_store.item.item +
            ' ' +
            element.township_store.item.au,
        },
        qty: element.quantity,
      }))
      console.log(items)
      setSelectedItem(items)
      addLoading(false)
    }
    fetchApi()
  }, [])

  return (
    <UserLayout>
      <Card>
        <CardBody>
          <ReviewPage
            showActions={false}
            selectItems={selectedItems}
            patient={1}
            date={dispense?.dispence_date}
          />
        </CardBody>
      </Card>
    </UserLayout>
  )
}
export default ReviewDispense
