import React, { useContext } from 'react'
import { useState } from 'react'
import LoadingContext from 'store/loadingContext'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import CustomNav from 'components/layouts/customNav.js'
import SideBar from '../components/sidebar/Sidebar'
import './user.scss'

const AdminLayout = ({ children }) => {
  const { loading } = useContext(LoadingContext)
  const [sidebarIsOpen, setSidebarOpen] = useState(true)
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen)

  return (
    <>
      <CustomNav toggleSidebar={toggleSidebar} isAdmin={true} />
      <SideBar isOpen={sidebarIsOpen} />
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader color="blue" />}
        text="Loading.."
      >
        {!loading && <div className="main-content">{children}</div>}
      </LoadingOverlay>
    </>
  )
}

export default AdminLayout
