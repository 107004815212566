import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getTownshipStores,
  getTownshipStore,
  addTownshipStore,
  editTownshipStore,
  deleteTownshipStore,
} from 'api/townshipStore'
const TownshipStoreContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})
export function TownshipStoreContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      let response = await getTownshipStores(params)
      setItems(response)
      return response
    },
    getEachData: async (id) => {
      let response = await getTownshipStore(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      if (await addTownshipStore(values)) {
        NotificationManager.success('Success')
      }
    },
    editData: async (values, id) => {
      if (await editTownshipStore(values, id)) {
        NotificationManager.success('Success')
      }
    },
    deleteData: async (id, visit_id) => {
      if (await deleteTownshipStore(id)) {
        NotificationManager.success('Success')
      }
    },
  }
  return (
    <TownshipStoreContext.Provider value={context}>
      {props.children}
    </TownshipStoreContext.Provider>
  )
}
export default TownshipStoreContext
