import { AuthContextProvider } from './authContext'
import { PatientContextProvider } from './patientContext'
import { SymptonContextProvider } from './symptonContext'
import { LabContextProvider } from './labContext'
import { ItemContextProvider } from './itemContext'
import { ItemRequestContextProvider } from './itemRequestContext'
import { LoadingContextProvider } from './loadingContext'
import { RiskFactorContextProvider } from './riskFactorContext'
import { VisitContextProvider } from './visitContext'
import { DmContextProvider } from './dmContext'
import { TownshipContextProvider } from './townshipContext'
import { WarehouseReceiveContextProvider } from './warehouseReceiveContext'
import { WarehouseIssueOutContextProvider } from './warehouseIssueOutContext'
import { WarehouseIssueContextProvider } from './warehouseIssueContext'
import { TownshipStoreContextProvider } from './townshipStoreContext'
import { ItemDispensingContextProvider } from './itemDispensingContext'
import { TownshipStoreIssueOutContextProvider } from './townshipStoreIssueOutContext'
import { HomeBasedCareContextProvider } from './homeBasedCareContext'
import { PhysicianContextProvider } from './physicianContext'
export const AllProviders = ({ children }) => {
  return (
    <AuthContextProvider>
      <PatientContextProvider>
        <SymptonContextProvider>
          <LabContextProvider>
            <ItemContextProvider>
              <ItemRequestContextProvider>
                <RiskFactorContextProvider>
                  <VisitContextProvider>
                    <DmContextProvider>
                      <TownshipContextProvider>
                        <WarehouseReceiveContextProvider>
                          <WarehouseIssueOutContextProvider>
                            <WarehouseIssueContextProvider>
                              <TownshipStoreContextProvider>
                                <ItemDispensingContextProvider>
                                  <TownshipStoreIssueOutContextProvider>
                                    <HomeBasedCareContextProvider>
                                      <PhysicianContextProvider>
                                        <LoadingContextProvider>
                                          {children}
                                        </LoadingContextProvider>
                                      </PhysicianContextProvider>
                                    </HomeBasedCareContextProvider>
                                  </TownshipStoreIssueOutContextProvider>
                                </ItemDispensingContextProvider>
                              </TownshipStoreContextProvider>
                            </WarehouseIssueContextProvider>
                          </WarehouseIssueOutContextProvider>
                        </WarehouseReceiveContextProvider>
                      </TownshipContextProvider>
                    </DmContextProvider>
                  </VisitContextProvider>
                </RiskFactorContextProvider>
              </ItemRequestContextProvider>
            </ItemContextProvider>
          </LabContextProvider>
        </SymptonContextProvider>
      </PatientContextProvider>
    </AuthContextProvider>
  )
}
