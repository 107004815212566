import { getData, sendData } from './apiService.js'
export async function getItemDispensings(params = {}) {
  let response = await getData(`townshipstoredispences`, params)
  return response
}
export async function getItemDispensing(id) {
  let response = await getData(`townshipstoredispences/${id}`)
  return response
}
export async function addItemDispensing(values) {
  return await sendData('townshipstoredispences', values)
}
export async function editItemDispensing(values, id) {
  return await sendData(`townshipstoredispences/${id}`, values, 'PUT')
}

export async function deleteItemDispensing(id) {
  return await sendData(`townshipstoredispences/${id}`, {}, 'DELETE')
}
