import { createContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getWarehouseReceives,
  getWarehouseReceive,
  addWarehouseReceive,
  editWarehouseReceive,
  deleteWarehouseReceive,
} from 'api/warehouseReceive'
const WarehouseReceiveContext = createContext({
  data: [],
  eachData: {},
  getData: () => {},
  getEachData: () => {},
  addData: () => {},
  editData: () => {},
})
export function WarehouseReceiveContextProvider(props) {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const context = {
    data: items,
    eachData: item,
    getData: async (params = {}) => {
      let response = await getWarehouseReceives(params)
      setItems(response)
      return response
    },
    getEachData: async (id) => {
      let response = await getWarehouseReceive(id)
      setItem(response)
      return response
    },
    addData: async (values) => {
      if (await addWarehouseReceive(values)) {
        NotificationManager.success('Success')
        window.location.reload()
      }
    },
    editData: async (values, id) => {
      if (await editWarehouseReceive(values, id)) {
        NotificationManager.success('Success')
      }
    },
    deleteData: async (id, visit_id) => {
      if (await deleteWarehouseReceive(id)) {
        NotificationManager.success('Success')
      }
    },
  }
  return (
    <WarehouseReceiveContext.Provider value={context}>
      {props.children}
    </WarehouseReceiveContext.Provider>
  )
}
export default WarehouseReceiveContext
