import React from 'react'
import { Form, Formik } from 'formik'
import { Row, Col, Input, Button, Label } from 'reactstrap'
import CustomSelect from '../elements/customSelect'
import CustomDatePicker from 'components/elements/customDatePicker'
import { issueOutTypes } from 'mock/item'
function IssueManageForm({ label, onSubmit }) {
  let initalValues = {
    quantity: '',
    date: '',
    type: '',
    remark: '',
  }
  return (
    <Formik
      initialValues={initalValues}
      validate={(values) => {
        const errors = {}
        return errors
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {(formik) => (
        <Form>
          <Row>
            <label>{label}</label>
            <Row className="mt-3">
              <Col md="6">
                <Label>Type</Label>
                <CustomSelect
                  options={issueOutTypes}
                  onChange={({ value }) => {
                    console.log(value)
                  }}
                  value={formik.values.type}
                />
              </Col>
              <Col md="6">
                <Label>Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                />
              </Col>
              <Col md="6">
                <Label>Date</Label>
                <CustomDatePicker
                  name="date"
                  value={formik.values.date}
                  onChange={(day) => formik.setFieldValue('date', day)}
                />
              </Col>
              <Col md="6">
                <Label>Remark</Label>
                <Input
                  name="remark"
                  onChange={formik.handleChange}
                  value={formik.values.remark}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <Button color="primary" type="submit">
                  Add
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
export default IssueManageForm
