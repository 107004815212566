import { createContext, useState } from "react";
const LoadingContext = createContext({
  loading: false,
  actionLoading:false,
  addLoading: () => {},
  addActionLoading: () => {},
});

export function LoadingContextProvider(props) {
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const context = {
    loading: loading,
    actionLoading: actionLoading,
    addLoading: (value) => {
      setLoading(value);
    },
    addActionLoading: (value) => {
      setActionLoading(value);
    },
  };
  return (
    <LoadingContext.Provider value={context}>
      {props.children}
    </LoadingContext.Provider>
  );
}

export default LoadingContext;
